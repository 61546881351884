import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import images from "../../../themes/appImage";
import { removeEmojis } from "../../../utils/helpers";
import {
  resetPassword,
  validateResetPasswordToken,
} from "../../../redux/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { appconstant, routes } from "../../../themes/appconstant";
import regex from "../../../themes/formikConstants/regex";
import initialValues from "../../../themes/formikConstants/initialValues";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = location.search.split("=")[1];
  const msg = useSelector((state) => state?.auth?.msg);
  const admin = useSelector((state) => state?.auth?.admin);
  const resetSucs = useSelector((state) => state?.auth?.resetSucs);

  const [show, setshow] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const onSubmit = () => {
    const values = formik.values;
    const newData = JSON.stringify({
      token,
      password: values?.password,
    });

    dispatch(resetPassword(newData));
  };

  const formik = useFormik({
    onSubmit,
    initialValues: initialValues?.resetPass(),
    validationSchema: regex?.resetPass,
  });

  useEffect(() => {


    const payload = JSON.stringify({ token, });

    dispatch(validateResetPasswordToken(payload, navigate));
  }, []);    // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (resetSucs) {
      setSuccessMsg(appconstant?.passChangedSuccessfully);
    } else if (msg || successMsg) {
      setshow(true);
    }

  }, [msg, successMsg, resetSucs]);
  return (show && msg) || successMsg ? (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div">
          <div
            className="login-form mt-top"
            style={{
              height:
                formik.errors?.password !== appconstant?.plz_enter_pass &&
                  formik.errors?.password !== undefined
                  ? "530px"
                  : "440px",
            }}
          >
            <div className="back">
              {" "}
              {
                admin ?
                  <button type="submit" className=" btn2  " onClick={() => navigate(routes?.login)}>
                    {" "}
                    <FontAwesomeIcon
                      className="icon-angle-left"
                      icon={faAngleLeft}
                    />
                  </button>
                  : null
              }
            </div>
            <div>
              {" "}
              <img src={images.login} alt="" />
            </div>

            {msg ? (
              <>
                <h3
                  style={{
                    fontSize: '21px',
                    fontWeight: '500'
                  }}
                >{appconstant?.expired} </h3>

                <div className="expire-msg">
                  <h1
                    style={{
                      fontWeight: '400',
                      fontSize: '18px'
                    }}>{msg}</h1>
                </div>
                <div className="login-link">
                  {
                    admin ? <button
                      className="login-link"
                      onClick={() => {
                        navigate(routes.login);
                      }}
                    >
                      {appconstant?.backLogin}
                    </button>
                      : null
                  }
                </div>
              </>
            ) : (
                <>
                  <h3
                    style={{
                      fontSize: '21px',
                      fontWeight: '500'
                    }}>{appconstant?.success}</h3>
                  <div className="expire-msg">
                    <h1
                      style={{
                        fontWeight: '400',
                        fontSize: '18px'
                      }}>{successMsg}</h1>
                  </div>
                  <div className="login-link">
                    {

                      admin ?
                        <button
                          className="login-link"
                          onClick={() => {
                            navigate(routes.login);
                          }}
                        >
                          {appconstant?.backLogin}
                        </button>
                        : null
                    }
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  ) : (
      msg
        ?
        null
        :
        <div className="image-bg">
          <div className="page-header">
            <div className="main-div">
              <div className="login-form mt-top">
                <div className="back">
                  {" "}
                  {
                    admin ?
                      <button type="submit" className=" btn2  " onClick={() => navigate(routes?.login)}>
                        {" "}
                        <FontAwesomeIcon
                          className="icon-angle-left"
                          icon={faAngleLeft}
                        />
                      </button>
                      : null
                  }
                </div>
                <div>
                  {" "}
                  <img src={images.login} alt="" />
                </div>
                <h4>{appconstant?.resetPass} </h4>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <div className="user">
                    <div
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      <input
                        type="password"
                        placeholder={appconstant?.password}
                        value={removeEmojis(formik.values.password.trim())}
                        id="password"
                        onChange={formik.handleChange}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }
                        }
                      />
                      {formik.errors.password ? (
                        <div className="error">{formik.errors.password}</div>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      <input
                        type="password"
                        placeholder={appconstant?.confirmPass}
                        value={removeEmojis(formik.values.confirmPassword.trim())}
                        id="confirmPassword"
                        onChange={formik.handleChange}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }
                        }
                      />
                      {formik.errors.confirmPassword ? (
                        <div className="error">{formik.errors.confirmPassword}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="login-link">
                    <button type="submit" className="login-link">
                      {appconstant?.reset}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    );
};
export default ResetPassword;
