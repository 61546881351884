import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages, } from "../../themes/appconstant";
import { toast } from "react-toastify";
import api from "../axios/apis";



function* postListSaga({ payload }) {
    console.log("postList");

    try {

        let response = yield call(api?.postList, payload);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: types.POST_LIST_SUCCESS, result: response.data, });
        }
        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.POST_LIST_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.POST_LIST_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}

function* postDeleteSaga({ payload }) {

    try {

        let response = yield call(api?.deletePost, payload);

        if (response?.status >= 200 && response?.status < 400) {

            toast.success(response?.message, { toastId: "success" });

      yield put({ type: types.POST_DELETE_SUCCESS,  });
        }
        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.POST_DELETE_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.POST_DELETE_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}

function* userDetailSaga({ payload }) {

    try {

        let response = yield call(api?.userDetails, payload);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: types.USER_DETAILS_SUCCESS, result: response.data, });

        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.USER_DETAILS_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.USER_DETAILS_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}

function* updateUserDetailsaga({ payload, navigate }) {

    try {

        let response = yield call(api?.updateUserDetails, payload);

        if (response?.status >= 200 && response?.status < 400) {

            toast.success(response?.message, { toastId: "success" });
            yield put({ type: types.USER_DETAILS_UPDATE_SUCCESS, result: response.data });
            navigate('/usermanagement')
        }
        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.USER_DETAILS_UPDATE_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.USER_DETAILS_UPDATE_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}

function* countryListSaga() {

    try {

        let response = yield call(api?.countryList);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: types.API_COUNTRY_LIST_SUCCESS, result: response.data, });
        }
        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_COUNTRY_LIST_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.API_COUNTRY_LIST_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}

function* StateListSaga({ payload }) {

    try {

        let response = yield call(api?.stateList, payload);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: types.API_STATE_LIST_SUCCESS, result: response.data });
        }
        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_STATE_LIST_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.API_STATE_LIST_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}

export default function* rootPostManagementSaga() {
    yield takeLatest(types.POST_LIST_LOAD, postListSaga);
    yield takeLatest(types.POST_DELETE_LOAD, postDeleteSaga);
   
}
