import React from "react";

//**  This is Editabel field function **/

const Textfiled1 = (props) => {
  const {
    max,
    type = "text",
    className,
    disabled,
    defaultValue,
    label,
    placeholder,
    id,
    value,
    onChange,
    error
  } = props;
  return (
    <div className="form-group"
      style={{
        marginBottom: error != null ? '35px' : '23px'
      }}
    >
      <label>{label}</label>
      <div className="input_fields">
        <input
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={`form-control ${className ?? ""}`}
          disabled={disabled}
          maxLength={max}
        />
        {error}
      </div>
    </div>
  );
};

export default Textfiled1;
