import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { appconstant } from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { notificationList } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

const NotificationManagement = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const notificationListData = useSelector((state) => state?.notifications?.notifications);

    const [apiBody, setApiBody] = useState({ offset: 0, limit: 10, search: null, sort: null, order: null });
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("")

    const dataSource = notificationListData?.data?.map((i, index) => {
        i.key = index
        return i
    })

    const handleTableChange = (page, sort) => {

        dispatch(notificationList({ ...apiBody, offset: page.current === 1 ? 0 : (page.current - 1) * 10, limit: 10, sort: sort?.columnKey ? sort.columnKey : null, order: sort?.order === "descend" ? -1 : sort?.order === "ascend" ? 1 : null }))
        setApiBody(prev => ({ ...prev, offset: page.current === 1 ? 0 : (page.current - 1) * 10, limit: 10, sort: sort?.columnKey ? sort.columnKey : null, order: sort?.order === "descend" ? -1 : sort?.order === "ascend" ? 1 : null }))
        setCurrentPage(page.current)
    }

    const handleInputChange = (e) => {
        let value = e.target.value.trimLeft()

        dispatch(notificationList({ ...apiBody, offset: 0, limit: 10, search: value, sort: null, order: null }))
        setApiBody(prev => ({ ...prev, offset: 0, limit: 10, search: value, sort: null, order: null }))
        setCurrentPage(1)
        setSearch(value)
    }
    const columns = [
        {
            title: appconstant?.srNo,
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            width: "100px",
            render: (value, item, index) => apiBody.offset + index + 1,
        },

        {
            title: appconstant?.title,
            dataIndex: "title",
            key: "title",
            sorter: true,
            render: (value, item, index) => <div style={{ width: '200px' }}>{item?.title}</div>
        },

        {
            title: appconstant?.description,
            dataIndex: "description",
            key: "description",
            sorter: true,
            render: (value, item, index) => <div style={{ width: '200px' }}>{item?.description}</div>
        },
        {
            title: appconstant?.dateTime,
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: true,
            align: "left",
            render: (value, item, index) => <div style={{ width: '200px' }}>{moment(item.createdAt).format("MM/DD/YYYY   hh:mm A")}</div>
        }
    ];

    useEffect(() => {
        document.title = appconstant?.t1d_friends;
        dispatch(notificationList(apiBody))

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <BreadcrumbFn
                path={["dashboard"]}
                pathName={[
                    <FontAwesomeIcon icon={faHome} />,
                    appconstant.notificationManagement,
                ]}
            />

            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                <button className="send-notification-button" onClick={() => navigate('/sendnotification')}> {appconstant.sendNotification} </button>
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">
                            {appconstant.notificationManagement}
                        </h6>
                        <input
                            type="text"
                            className="iocn-search  search-bar"
                            placeholder={appconstant?.search}
                            value={search}

                            onChange={(e) => { handleInputChange(e) }}
                        />
                    </div>

                    <Table

                        dataSource={dataSource}
                        columns={columns}
                        showSorterTooltip={false}
                        pagination={{ total: notificationListData?.paginationData?.totalCount, current: currentPage }}
                        onChange={(page, _, sort) => { handleTableChange(page, sort) }}
                    />
                </div>
            </div>
        </div>
    );
};
export default NotificationManagement;
