import {
  Link,
  useNavigate,
} from "react-router-dom";
import { useFormik } from "formik";
import ModalPop from "../../../common/modal";
import images from "../../../themes/appImage";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, updateState } from "../../../redux/actions";
import { appconstant, routes } from "../../../themes/appconstant";
import regex from "../../../themes/formikConstants/regex";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import initialValues from "../../../themes/formikConstants/initialValues";

const ForgotPassword = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const apiSuccess = useSelector(state => state?.auth?.forgotPasswordSuccess);

  useEffect(() => {
    if (apiSuccess) {
      setIsModalVisible(true);
    }

  }, [apiSuccess])

  const fragment = (
    <>
      <div className="success-deta">
        A reset password link has been sent successfully to your registered
        email address.
      </div>
    </>
  );
  const successBtn = (
    <>
      <div className="forgot-text-btn">
        <Link to="/login">
          <button className="forgot_text" onClick={() => { dispatch(updateState("forgotPasswordSuccess", false)) }}>Ok</button>
        </Link>
      </div>
    </>
  );
  const onSubmit = () => {

    const payload = {
      email: formik.values.email
    }

    dispatch(forgotPassword(payload, navigate));
  };
  const formik = useFormik({
    onSubmit,
    initialValues: initialValues?.forgotPass(),
    validationSchema: regex?.forgotPass,
  });

  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div">
          <div className="login-form mt-top">
            <div className="back">
              {" "}
              <button type="submit" className="btn2" onClick={() => navigate(routes?.login)} >
                {" "}
                <FontAwesomeIcon
                  className="icon-angle-left"
                  icon={faAngleLeft}
                />
              </button>
            </div>
            <div>
              {" "}
              <img src={images.login} alt="" />
            </div>
            <h4>{appconstant?.forgotPass}</h4>

            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="user">
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <input
                    type="text"
                    placeholder={appconstant?.emailAddrs}
                    value={formik.values.email.trimLeft()}
                    id={"email"}
                    maxLength={320}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <button className="login-link" type="submit">
                {appconstant?.send}
              </button>
            </form>

            {isModalVisible && (
              <ModalPop
                content={fragment}
                btnSuccess={successBtn}
                btnCancel={true}
                handleSuccess={() => { }}
                handleCancel={() => { }}
                isVisible={isModalVisible}
                center="deta-first-model"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
