import React, { useState, useEffect } from "react";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { appconstant } from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Tabs, Input } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { privacyPolicy, termsAndConditions, updatePrivacyPolicyAndTermsAndConditions, updateAppSettingState } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;


const AppSetting = (props) => {

    document.title = appconstant?.t1d_friends;
    const [initialValues, setInitialValues] = useState({ privacyPolicy: "", termsAndConditions: "" })
    const [keyToUpdate, setKeyToUpdate] = useState(null)
    const navigate = useNavigate()

    const appSettingData = useSelector((state) => state.appSetting);

    const dispatch = useDispatch()

    useEffect(() => {

        dispatch(privacyPolicy(navigate))
        dispatch(termsAndConditions(navigate))

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        setInitialValues({ privacyPolicy: appSettingData.privacyPolicy, termsAndConditions: appSettingData.termsAndConditions })

    }, [appSettingData])

    const formik = useFormik({

        enableReinitialize: true,
        initialValues,

        validationSchema: Yup.object().shape({
            privacyPolicy: Yup.string()
                .min(3, '*Please enter minimum 3 characters.')
                .required('Please enter privacy policy.')
                .trim(''),
            termsAndConditions: Yup.string()
                .min(3, '*Please enter minimum 3 characters.')
                .required('Please enter terms & conditions.')
                .trim('')
        }),

        onSubmit: values => {

            setIsEditing(false)

            dispatch(updatePrivacyPolicyAndTermsAndConditions(keyToUpdate && keyToUpdate === "terms" ? { terms: formik.values.termsAndConditions } : { privacyPolicy: formik.values.privacyPolicy }))
            dispatch(updateAppSettingState(keyToUpdate && keyToUpdate === "terms" ? { terms: formik.values.termsAndConditions } : { privacyPolicy: formik.values.privacyPolicy }))
        },
    });

    const [isEditing, setIsEditing] = useState(false)

    const editButtonClick = () => setIsEditing(true)
    const onTabChange = () => setIsEditing(false)


    return (
        <div>
            <BreadcrumbFn
                path={["dashboard"]}
                pathName={[
                    <FontAwesomeIcon icon={faHome} />,
                    appconstant.AppSetting,
                ]}
            />

            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                <div className="content-e">

                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">  </div>

                    {
                        isEditing ?

                            <Tabs onChange={(e) => { onTabChange() }}>

                                <Tabs.TabPane tab="Privacy Policy" key="1">

                                    <div className="app-setting-tab-heading">Privacy Policy</div>

                                    <div className="app-setting-tab-container">
                                        <TextArea
                                            className="app-setting-tab-input"
                                            onChange={formik.handleChange}
                                            value={formik.values.privacyPolicy.trimLeft()}
                                            name="privacyPolicy"

                                        />

                                        {formik.errors.privacyPolicy ? (<div className="app-setting-error">{formik.errors.privacyPolicy}</div>) : null}

                                        <button
                                            className="app-setting-tab-button button-list"
                                            type="button"
                                            onClick={() => {
                                                formik.handleSubmit()
                                                setKeyToUpdate("privacyPolicy")
                                            }}
                                        >
                                            Update
                                        </button>
                                    </div>

                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Terms & Conditions" key="2">

                                    <div className="app-setting-tab-heading">Terms & Conditions</div>

                                    <div className="app-setting-tab-container">

                                        <TextArea
                                            className="app-setting-tab-input"
                                            name="termsAndConditions"
                                            value={formik.values.termsAndConditions.trimLeft()}
                                            onChange={formik.handleChange}
                                        />

                                        {formik.errors.termsAndConditions ? (<div className="app-setting-error">{formik.errors.termsAndConditions}</div>) : null}

                                        <button
                                            className="app-setting-tab-button button-list"
                                            type="button"
                                            onClick={() => {
                                                formik.handleSubmit()
                                                setKeyToUpdate("terms")
                                            }}
                                        >
                                            Update
                                        </button>
                                    </div>

                                </Tabs.TabPane>
                            </Tabs>

                            :

                            <Tabs onChange={(e) => { onTabChange() }}>

                                <Tabs.TabPane tab="Privacy Policy" key="1">

                                    <div className="app-setting-tab-heading">Privacy Policy</div>

                                    <div className="app-setting-tab-container">

                                        <div className="app-setting-tab-description">

                                            {appSettingData?.privacyPolicy}
                                        </div>

                                        <button
                                            className="app-setting-tab-button button-list"
                                            onClick={() => editButtonClick()}
                                        >
                                            Edit
                                        </button>
                                    </div>

                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Terms & Conditions" key="2">

                                    <div className="app-setting-tab-heading">Terms & Conditions</div>

                                    <div className="app-setting-tab-container">

                                        <div className="app-setting-tab-description">

                                            {appSettingData?.termsAndConditions}
                                        </div>

                                        <button
                                            className="app-setting-tab-button button-list"
                                            onClick={() => editButtonClick()}
                                        >
                                            Edit
                                </button>
                                    </div>

                                </Tabs.TabPane>
                            </Tabs>
                    }

                </div>
            </div >
        </div >
    );
};
export default AppSetting;
