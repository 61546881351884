import APIKit from "./baseUrlsWithToken";
import { appMessages } from "../../../themes/appconstant";
import { toast } from "react-toastify";

// headers
const jsonHeader = { "access-control-allow-origin": "*", "Content-Type": "application/json", Accept: "application/json" }
const formDataHeader = { "access-control-allow-origin": "*", "Content-Type": "multipart/form-data", Accept: "application/json" }

let navigate = null;

export const getNavigate = (data) => navigate = data

// common api handler
const apiHandlerFunction = (handler) => {

  if (window.navigator.onLine) {

    return handler

      .then(result => { return { success: true, status: result.status, message: result.data.message, data: result?.data } })

      .catch((err) => {

        if (err?.response?.status) {

          if (err?.response?.status >= 400 && err?.response?.status < 500) {

            if (err?.response?.status === 401 || err?.response?.status === 403) {

              toast.error(err.response.data.message, { toastId: "err" });
              localStorage.clear();
              navigate("/login")

            }
            return { success: false, status: err.response.request.status, message: err?.response?.data?.message }
          }
          else {

            toast.error(appMessages.messageStatus500, { toastId: "err" });
            return { success: false, status: 500, message: appMessages.messageStatus500 }
          }
        }
        else {

          toast.error(appMessages.messageStatus500, { toastId: "err" });
          return { success: false, status: 500, message: appMessages.messageStatus500 }
        }
      })
  }
  else toast.error(appMessages.offline, { toastId: "err" });
}

// Methods
export const Method = {

  // post method
  post: (url, body, formData) => apiHandlerFunction(APIKit.post(url, body, { headers: formData ? formDataHeader : jsonHeader })),

  // get method
  get: (url) => apiHandlerFunction(APIKit.get(url, { headers: jsonHeader })),

  // put method 
  put: (url, body, formData) => apiHandlerFunction(APIKit.put(url, body, { headers: formData ? formDataHeader : jsonHeader })),

  // delete method
  delete: (url) => apiHandlerFunction(APIKit.delete(url, { headers: jsonHeader })),
};


export default Method;
