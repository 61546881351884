import { Navigate } from "react-router-dom";
import React from "react";

const PrivateRoute = ({ component }) => {
  return localStorage.token ? (
    component
  ) : (
      <Navigate to="/" />
    );
};

export default PrivateRoute;
