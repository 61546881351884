import React, { useEffect } from "react";
import images from "../../../themes/appImage";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeEmojis } from "../../../utils/helpers";
import { loginAction } from "../../../redux/actions";
import { useFormik } from "formik";
import regex from "../../../themes/formikConstants/regex";
import initialValues from "../../../themes/formikConstants/initialValues";
import { appconstant } from "../../../themes/appconstant";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.history?.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history?.pushState(null, document.title, window.location.href);
    });

  }, [])

  const onSubmit = () => {
    const values = formik.values;
    const newData = JSON.stringify({
      email: (values?.email).toLocaleLowerCase(),
      password: values?.password,
    });

    dispatch(loginAction(newData, navigate));
  };
  const formik = useFormik({
    onSubmit,
    validationSchema: regex?.login,
    initialValues: initialValues?.login(),
  });


  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div">
          <div className="login-form">
            <div>
              {" "}
              <img src={images.login} alt="" />
            </div>
            <h3>{appconstant?.adminLogin} </h3>

            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="user">
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <input
                    type="text"
                    placeholder={appconstant?.emailAddrs}
                    value={formik.values.email.trimLeft()}
                    id="email"
                    maxLength={320}
                    onChange={formik.handleChange}
                    autoComplete={"false"}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>

                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <input
                    type="password"
                    placeholder={appconstant?.password}
                    value={removeEmojis(formik.values.password.trimLeft())}
                    id="password"
                    onChange={formik.handleChange}
                    autoComplete={"false"}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </div>
              </div>
              <div className="login-link">
                <button type="submit" className="login-link">{appconstant?.login}</button>
              </div>
            </form>

            <div className="forgot-link">
              <Link to="/forgotpassword">{appconstant?.forgotPass}?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
