import { Table, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { appconstant } from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { reportList, blockUser } from "../../redux/actions";

const ReportManagement = () => {

    const dispatch = useDispatch()
    const usersListData = useSelector((state) => state.reportManagement.userList);
    const [apiBody, setApiBody] = useState({ offset: 0, limit: 10, search: null, sort: null, order: null });
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("")
    const [currentBlockedUser, setCurrentBlockedUser] = useState({})

    const [blockUnblockTitle, setblockUnblockTitle] = useState("Block");
    const disableModel = (record, index) => {
        Modal.confirm({
            title:
                blockUnblockTitle === appconstant?.block
                    ? appconstant?.block
                    : appconstant?.unblock,
            content:
                blockUnblockTitle === appconstant?.block
                    ? appconstant?.u_sure_block_user
                    : appconstant?.u_sure_unblock_user,
            okText: appconstant?.yes,
            centered: true,
            cancelText: appconstant?.no,
            onOk() {
                blockUnblockTitle === appconstant?.block
                    ? setblockUnblockTitle(appconstant?.unblock)
                    : setblockUnblockTitle(appconstant?.block);

                dispatch(blockUser({ _id: record._id, block: currentBlockedUser?.adminBlock }))
                dataSource[index]['adminBlock'] = !dataSource[index]['adminBlock']
            },
            onCancel() { },
            className: "new-button",
        });
    };

    const dataSource = usersListData?.reportList?.map((i, index) => {
        i.key = index
        return i
    })

    const handleTableChange = (page, sort) => {

        dispatch(reportList({ ...apiBody, offset: page.current === 1 ? 0 : (page.current - 1) * 10, limit: 10, sort: sort?.columnKey ? sort.columnKey : null, order: sort?.order === "descend" ? -1 : sort?.order === "ascend" ? 1 : null }))
        setApiBody(prev => ({ ...prev, offset: page.current === 1 ? 0 : (page.current - 1) * 10, limit: 10, sort: sort?.columnKey ? sort.columnKey : null, order: sort?.order === "descend" ? -1 : sort?.order === "ascend" ? 1 : null }))
        setCurrentPage(page.current)
    }

    const handleInputChange = (e) => {
        let value = e.target.value.trimLeft()

        dispatch(reportList({ ...apiBody, offset: 0, limit: 10, search: value, sort: null, order: null }))
        setApiBody(prev => ({ ...prev, offset: 0, limit: 10, search: value, sort: null, order: null }))
        setCurrentPage(1)
        setSearch(value)
    }
    const columns = [
        {
            title: appconstant?.srNo,
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            width: "100px",
            render: (value, item, index) => index + 1,
        },

        {
            title: appconstant?.name,
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (value, item, index) => <div style={{ width: '200px' }}>{item?.name}</div>
        },

        {
            title: appconstant?.emailAddrs,
            dataIndex: "email",
            key: "email",
            sorter: true,
            render: (value, item, index) => <div style={{ width: '200px' }}>{item?.email}</div>
        },
        {
            title: appconstant?.Reports,
            dataIndex: "count",
            key: "count",
            sorter: true,
        },

        {
            title: appconstant?.action,
            dataIndex: "Posted",
            key: "Posted",
            render: (title, data, index) => {

                return (
                    <div>

                        <button type="submit" onClick={() => {

                            setCurrentBlockedUser(data)
                            disableModel(data, index)
                        }}>
                            {data?.adminBlock ? appconstant.unblock : appconstant.block}
                        </button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        document.title = appconstant?.t1d_friends;
        dispatch(reportList(apiBody))
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <BreadcrumbFn
                path={["dashboard"]}
                pathName={[
                    <FontAwesomeIcon icon={faHome} />,
                    appconstant.ReportManagement,
                ]}
            />
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">
                            {appconstant.ReportManagement}
                        </h6>
                        <input
                            type="text"
                            className="iocn-search  search-bar"
                            placeholder={appconstant?.search}
                            value={search}

                            onChange={(e) => { handleInputChange(e) }}
                        />
                    </div>

                    <Table

                        dataSource={dataSource}
                        columns={columns}
                        showSorterTooltip={false}
                        pagination={{ total: usersListData?.paginationData?.totalCount, current: currentPage }}
                        onChange={(page, _, sort) => { handleTableChange(page, sort) }}
                    />
                </div>
            </div>
        </div>
    );
};
export default ReportManagement;
