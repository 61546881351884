import * as Yup from "yup";
import { useFormik } from "formik";
import ModalPop from "../../common/modal";
import Textfiled1 from "../../common/Textfiled";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { appconstant, appMessages } from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { userDetails, updateUserDetails, countryList, stateList } from "../../redux/actions";
import { capitalizeFirstLetter, removeEmojis } from "../../utils/helpers";
import { Select } from 'antd';
import moment from 'moment'
import { toast } from "react-toastify";
import { Tooltip } from 'antd';

const EditUserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const userId = location?.state
  const UserDetailsData = useSelector((state) => state.userManagement.userDetails);
  const countryListData = useSelector((state) => state.userManagement.countryList);
  const stateListData = useSelector((state) => state.userManagement.stateList);

  const [upload, setUpload] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({ firstName: "", lastName: "", state: "", country: "", email: "", userName: '', education: '' });
  const [currentCountry, setCurrentCountry] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const [currentSchool, setCurrentSchool] = useState(null);

  const schoolLevel = [
    {
      label: "High School",
      value: "highSchool"
    },
    {
      label: "Middle School",
      value: "middleSchool"
    },
    {
      label: "College",
      value: "college"
    },
    {
      label: "Graduate",
      value: "graduates"
    }

  ]

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .test(
        "trim",
        "Please enter first name.",
        (value) => {

          if (value) value = removeEmojis(value)
          return value?.trim()?.length > 0
        })
      .matches(
        /^[a-zA-Z]*$/,
        "Please enter valid first name."
      )
      .trim()
      .required("Please enter first name.")
      .max(30, "Maximum 30 characters are allowed.")
      .min(2, "First name should be at least 2 characters long."),

    lastName: Yup.string()
      .test(
        "trim",
        "Please enter last name.",
        (value) => {

          if (value) value = removeEmojis(value)
          return value?.replace(/ /g, "")?.length > 0
        })
      .matches(
        /^[a-zA-Z]*$/,
        "Please enter valid last name."
      )
      .trim()
      .required("Please enter last name.")
      .max(30, "Maximum 30 characters are allowed.")
      .min(2, "Last name should be at least 2 characters long."),

    country: Yup.string()
      .required("Please select country."),
    state: Yup.string()
      .required("Please select state.")
  });

  const handleCountryChange = (data) => {

    setInitialValues(x => ({ ...x, country: data, state: "" }))
    setCurrentCountry(countryListData.find(x => x._id === data))
    setCurrentState(null)
    dispatch(stateList({ countryId: data }))
  }

  const handleStateChange = (data) => {

    setCurrentState(stateListData.find(x => x._id === data))
    setInitialValues(x => ({ ...x, state: data }))
  }

  const handleEducationChange = (data) => {

    let school = schoolLevel.find(x => x.value === data)

    setCurrentSchool(school.label)
    setInitialValues(x => ({ ...x, education: data }))
  }

  const onSubmit = () => {

    let formData = new FormData()

    formData.append("userId", userId.userId)
    formData.append("firstName", removeEmojis(formik.values.firstName))
    formData.append("lastName", removeEmojis(formik.values.lastName))
    if (initialValues?.state && initialValues.state !== "N/A") formData.append("state", initialValues.state)
    if (initialValues.country && initialValues.country !== "N/A") formData.append("country", initialValues.country)
    if (initialValues.education) formData.append("education", initialValues.education)
    if (upload) formData.append("profileImage", upload)

    dispatch(updateUserDetails(formData, navigate))
  };

  const formik = useFormik({
    enableReinitialize: true,
    onSubmit,
    initialValues,
    validationSchema,
  });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const successBtn = (
    <>
      <div className="forgot-text-btn">
        <Link to="/usermanagement">
          <button className="forgot_text">Ok</button>
        </Link>
      </div>
    </>
  );

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const fragment = (
    <>
      <div className="success-deta">User details updated successfully.</div>
    </>
  );

  useEffect(() => {
    document.title = appconstant?.t1d_friends;
    window.scrollTo(0, 0);
    dispatch(countryList())
    dispatch(userDetails(userId))

  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitialValues({
      firstName: UserDetailsData?.firstName ?? "N/A",
      lastName: UserDetailsData?.lastName ?? "N/A",
      state: UserDetailsData?.state ? UserDetailsData?.state._id : "N/A",
      country: UserDetailsData?.country ? UserDetailsData?.country._id : "N/A",
      email: UserDetailsData?.email ?? "N/A",
      userName: UserDetailsData?.userName ?? "N/A",
      education: UserDetailsData?.education
    })


    setCurrentCountry(UserDetailsData?.country ? UserDetailsData?.country : countryListData.find(x => x._id === UserDetailsData?.country?._id))
    setCurrentState(UserDetailsData?.state ? UserDetailsData?.state : stateListData.find(x => x._id === UserDetailsData?.state?._id))
    setCurrentSchool(UserDetailsData?.education ? schoolLevel.find(x => x.value === UserDetailsData.education)?.label : null)
    if (UserDetailsData?.country) dispatch(stateList({ countryId: UserDetailsData.country._id }))

  }, [UserDetailsData])// eslint-disable-line react-hooks/exhaustive-deps



  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "usermanagement"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.UserManagemen,
          appconstant.EditUserDetail,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.EditUserDetail}
            </h6>
          </div>

          <div className="wrapper_line view_modules view ">



            <Tooltip
              title="Click to update profile image."
              placement="right"
            >
              <label className="user_detail_image_container cursor-pointer" htmlFor="profileImageUploader">


                {
                  UserDetailsData?.profileImage ?
                    upload ? <img className="user_detail_image" src={URL.createObjectURL(upload)} alt="" />
                      : <img className="user_detail_image" src={appconstant.baseUrlForFiles + UserDetailsData?.profileImage} alt="" />
                    : null
                }

                <FontAwesomeIcon icon={faPlus} className="edit-user-image-icon" />
              </label>
            </Tooltip>

            <input
              id="profileImageUploader"
              type="file"
              style={{ display: "none" }}
              accept="image/png,image/jpeg,image/jpg"
              onChange={(e) => {

                if (window.navigator.onLine) {

                  if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {

                    if (e.target.files[0].size <= 20971520) setUpload(e.target.files[0])
                    else toast.error(appconstant.fileShouldBeLessThan20mb)
                  }
                  else toast.error(appconstant.invalidImageFormat)
                }
                else toast.error(appMessages.offline)

              }}
            />
            <Textfiled1
              max={30}
              label={appconstant.firstName}
              id={"firstName"}
              type={"text"}
              onChange={(e) => {

                formik.handleChange(e)
                setInitialValues(x => ({ ...x, firstName: e.target.value.trim() }))
              }}
              value={capitalizeFirstLetter(formik.values.firstName.trimLeft())}
              placeholder={appconstant?.firstName}
              error={
                formik.errors.firstName ? (
                  <div
                    className="error"
                    style={{
                      paddingLeft: "0px",
                    }}
                  >
                    {formik.errors.firstName}
                  </div>
                ) : null
              }
            />

            <Textfiled1
              max={30}
              label={appconstant.lastName}
              id={"lastName"}
              type={"text"}
              onChange={(e) => {

                formik.handleChange(e)
                setInitialValues(x => ({ ...x, lastName: e.target.value.trim() }))
              }} value={capitalizeFirstLetter(formik.values.lastName.trimLeft())}
              placeholder={appconstant?.lastName}
              error={
                formik.errors.lastName ? (
                  <div
                    className="error"
                    style={{
                      paddingLeft: "0px",
                    }}
                  >
                    {formik.errors.lastName}
                  </div>
                ) : null
              }
            />

            <div className="edit-user-details-dropdown-container">

              <label> Education Level</label>
              <Select
                className="edit-user-details-dropdown"
                defaultValue="School Level"
                onChange={handleEducationChange}
                options={schoolLevel}
                value={currentSchool ?? "Select"}
              />

              {/* {
                formik.errors.state ? (<div className="error" style={{ paddingLeft: "0px", }} > {formik.errors.state} </div>) : null
              } */}
            </div>


            <Textfiled1
              label={appconstant.dob}
              value={UserDetailsData?.dob ? moment(UserDetailsData?.dob).format("MM/DD/YYYY") : "N/A"}
              disabled={true}
            />


            <Textfiled1
              label={appconstant.yod}
              value={UserDetailsData?.yod ? moment(UserDetailsData?.yod).format("YYYY") : "N/A"}
              disabled={true}
            />

            <div className="edit-user-details-dropdown-container">

              <label> Country</label>
              <Select
                className="edit-user-details-dropdown"
                onChange={handleCountryChange}
                options={countryListData.map(item => {

                  item.label = item?.name
                  item.value = item?._id
                  return item
                })}
                value={currentCountry?.name ?? "Select"}
              />

              {
                formik.errors.country ? (<div className="error" style={{ paddingLeft: "0px", }} > {formik.errors.country} </div>) : null
              }
            </div>

            <div className="edit-user-details-dropdown-container">

              <label> State</label>
              <Select
                className="edit-user-details-dropdown"
                defaultValue="State"
                onChange={handleStateChange}
                options={stateListData.map(item => {

                  item.label = item?.name
                  item.value = item?._id
                  return item
                })}
                value={currentState?.name ?? "Select"}
              />

              {
                formik.errors.state ? (<div className="error" style={{ paddingLeft: "0px", }} > {formik.errors.state} </div>) : null
              }
            </div>

            <Textfiled1
              label={appconstant.userName}
              value={initialValues?.userName}
              disabled={true}
            />

            <Textfiled1
              label={appconstant.Email}
              value={initialValues?.email?.toLocaleLowerCase()}
              disabled={true}
            />

            <div className="up-btn">
              {" "}
              <button type="submit" className="button-list" onClick={() => { formik.handleSubmit() }}>
                {appconstant.buttonupate}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      {isModalVisible && (
        <ModalPop
          content={fragment}
          btnSuccess={successBtn}
          btnCancel={true}
          handleSuccess={handleOk}
          handleCancel={handleCancel}
          isVisible={isModalVisible}
          center="deta-first-model"
        />
      )}
    </div>
  );
};
export default EditUserDetails;
