import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages, } from "../../themes/appconstant";
import api from "../axios/apis";
import { toast } from "react-toastify";


function* privacyPolicySaga({ navigate }) {

    try {

        let response = yield call(api.privacyPolicy, navigate);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: types.API_PRIVACY_POLICY_SUCCESS, result: response.data, });
        else yield put({ type: types.API_PRIVACY_POLICY_FAILED });
    }
    catch (error) {

        yield put({ type: types.API_PRIVACY_POLICY_FAILED });
    }
}

function* termsAndConditionsSaga({ navigate }) {

    try {

        let response = yield call(api.termsAndConditions, navigate);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: types.API_TERMS_AND_CONDITIONS_SUCCESS, result: response.data, });
        else yield put({ type: types.API_TERMS_AND_CONDITIONS_FAILED });
    }
    catch (error) {

        yield put({ type: types.API_TERMS_AND_CONDITIONS_FAILED });
    }
}

function* editPrivacyPolicyAnd({ payload }) {

    try {
        let response = yield call(api.updatePrivacyPolicyAndTermsAndConditions, payload);

        if (response?.status >= 200 && response?.status < 400) {

            toast.success(response.message, { toastId: "success" });
            yield put({ type: types.API_UPDATE_PRIVACY_POLICY_SUCCESS, result: response, payload });
        }
        else yield put({ type: types.API_UPDATE_PRIVACY_POLICY_FAILED });
    }
    catch (error) {

        yield put({ type: types.API_UPDATE_PRIVACY_POLICY_FAILED });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }

}


export default function* rootAppSettingSaga() {
    yield takeLatest(types.API_PRIVACY_POLICY_LOAD, privacyPolicySaga);
    yield takeLatest(types.API_TERMS_AND_CONDITIONS_LOAD, termsAndConditionsSaga);
    yield takeLatest(types.API_UPDATE_PRIVACY_POLICY_LOAD, editPrivacyPolicyAnd);

}
