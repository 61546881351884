import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages, } from "../../themes/appconstant";
import { toast } from "react-toastify";
import api from "../axios/apis";


function* sendNotification({ payload, navigate }) {

    try {

        let response = yield call(api?.sendNotification, payload);

        if (response?.status >= 200 && response?.status < 400) {

            navigate("/notificationmanagement")
            toast.success(response?.message, { toastId: "success" });
            yield put({ type: types.API_SEND_NOTIFICATION_SUCCESS, result: response.data, });

        }
        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_SEND_NOTIFICATION_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.API_SEND_NOTIFICATION_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}

function* notificationsList({ payload }) {

    try {

        let response = yield call(api?.notificationList, payload);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: types.API_NOTIFICATION_LIST_SUCCESS, result: response.data, });
        }
        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_NOTIFICATION_LIST_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.API_NOTIFICATION_LIST_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}


export default function* rootNotificationSaga() {
    yield takeLatest(types.API_SEND_NOTIFICATION_LOAD, sendNotification)
    yield takeLatest(types.API_NOTIFICATION_LIST_LOAD, notificationsList)
}
