import axios from "axios";
import { appconstant } from '../../../themes/appconstant'

// server url
const baseURL = appconstant.baseUrl
// const baseURL = "http://1.6.98.141:2021/api/v1"


const APIKit = axios.create({ baseURL, timeout: 60000000, });

APIKit.interceptors.request.use(async (config) => {
  console.log(config,"postId");
  if (localStorage.getItem("token")) config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

export default APIKit;