import { combineReducers } from "redux";
import { authenticationReducer as auth } from "./authenticationReducer";
import { changePassReducer as changePass } from "./changePassReducer";
import { appSettingReducer as appSetting } from "./appSettingReducer";
import { userManagementReducer as userManagement } from "./userManagementReducer";
import { ReportManagementReducer as reportManagement } from "./reportManagementReducer";
import { DashboardReducer as dashboard } from './dashboardReducer'
import { notificationsManagementReducer as notifications } from './notificationsReducer'
import {PostManagementReducer as postManagement} from "./postReducer"

const rootReducer = combineReducers({
    auth,
    changePass,
    appSetting,
    userManagement,
    reportManagement,
    dashboard,
    notifications,
    postManagement
});

export default rootReducer;
