import "./App.css";
import "../src/css/style.css";

import "../src/css/responsive.css";
import "antd/dist/antd.css";
import RoutesNew from "./Route/Routes";
import { Provider } from "react-redux";
import { store } from "./redux/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getNavigate } from './redux/axios/helpers/methods'
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const App = () => {

  const navigate = useNavigate()

  useEffect(() => {
    getNavigate(navigate)
  }, [])// eslint-disable-line react-hooks/exhaustive-deps



  return (
    <Provider store={store}>
      <RoutesNew />

      <ToastContainer position="top-right" autoClose={1500} />
    </Provider>
  );
}

export default App;
// auth module
