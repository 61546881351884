
import { apiConstants as types } from "../../themes/appconstant";

// login 
export const loginAction = (payload, navigate) => ({ type: types.API_LOGIN_LOAD, payload, navigate });

// update authentication state
export const updateState = (key, payload) => ({ type: types.UPDATE_STATE, key, payload });

// forgot password
export const forgotPassword = (payload, navigate) => ({ type: types.API_FORGOT_PASSWORD_LOAD, payload, navigate });

// reset password link validation
export const validateResetPasswordToken = (payload, navigate) => ({ type: types.API_CHECK_RESET_PASSWORD_LOAD, payload, navigate });

// reset password link validation (expired or active)
export const validateResetPasswordTokenMsg = (payload) => ({ type: types.API_CHECK_RESET_PASSWORD_MSG, payload });

// reset password
export const resetPassword = (payload) => ({ type: types.API_RESET_PASSWORD_LOAD, payload });

// change passsword
export const changePassword = (payload, navigate) => ({ type: types.API_CHANGE_PASSWORD_LOAD, payload, navigate })

// logout
export const logOut = (navigate) => ({ type: types.API_LOGOUT_LOAD, navigate })

// privacy policy
export const privacyPolicy = (navigate) => ({ type: types.API_PRIVACY_POLICY_LOAD, navigate })

// terms and conditions
export const termsAndConditions = (navigate) => ({ type: types.API_TERMS_AND_CONDITIONS_LOAD, navigate })

// update privacy policy and terms & conditions
export const updatePrivacyPolicyAndTermsAndConditions = (payload, navigate) => ({ type: types.API_UPDATE_PRIVACY_POLICY_LOAD, payload, navigate })

// update app setting state
export const updateAppSettingState = (payload) => ({ type: types.UPDATE_STATE_FOR_APP_SETTING, payload });

// user list
export const userList = (payload) => ({ type: types.USER_LIST_LOAD, payload });

// block user
export const blockUser = (payload) => ({ type: types.USER_BLOCK_LOAD, payload });
// block user
export const deletePost = (payload) => ({ type: types.POST_DELETE_LOAD, payload });

// user details
export const userDetails = (payload) => ({ type: types.USER_DETAILS_LOAD, payload });

// edit user details
export const updateUserDetails = (payload, navigate) => ({ type: types.USER_DETAILS_UPDATE_LOAD, payload, navigate });

// user list
export const reportList = (payload) => ({ type: types.REPORT_LIST_LOAD, payload });

// post list
export const postList = (payload) => ({ type: types.POST_LIST_LOAD, payload });

// dashboard
export const dashboard = () => ({ type: types.API_DASHBOARD_LOAD });

// country list
export const countryList = () => ({ type: types.API_COUNTRY_LIST_LOAD });

// state list
export const stateList = (payload) => ({ type: types.API_STATE_LIST_LOAD, payload });

// Send Notification
export const sendNotifications = (payload, navigate) => ({ type: types.API_SEND_NOTIFICATION_LOAD, payload, navigate });

// Notification List
export const notificationList = (payload) => ({ type: types.API_NOTIFICATION_LIST_LOAD, payload });