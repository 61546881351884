import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome
} from "@fortawesome/free-solid-svg-icons";
import { appconstant, routes } from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import images from "../../themes/appImage";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/actions";


const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = appconstant?.t1d_friends;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[<FontAwesomeIcon icon={faHome} />, appconstant.settings]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 class="text-white text-capitalize ps-3">
              {appconstant.settings}
            </h6>
          </div>

          <div className="wrapper_line view_modules view">
            <div className="flex">
              <div className="d-flex target">
                <button
                  style={{
                    background: 'transparent',
                    border: '0px'
                  }}
                  onClick={() => {
                    navigate(routes?.changePass);
                  }}
                >
                  <div className="text">
                    <img src={images.change} alt="" />
                    <p>{appconstant?.changepassword}</p>
                  </div>
                </button>

                {/* logout button */}
                <button
                  style={{
                    background: 'transparent',
                    border: '0px'
                  }}
                  onClick={() => { dispatch(logOut(navigate)) }}
                >
                  <div className="">
                    <img src={images.room} alt="" />
                    <p>{appconstant?.logout}</p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Settings;
