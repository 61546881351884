import { apiConstants as types } from "../../themes/appconstant";

const initialState = {
    isLoading: false,
    userList: [],
    body: { offset: 0, limit: 10, search: null, sort: null, order: null },
    userDetails: {},
    countryList: [],
    stateList: []
};

export const userManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.USER_LIST_LOAD: return { ...state, isLoading: true };
        case types.USER_LIST_SUCCESS: return { ...state, isLoading: false, userList: action?.result?.data };
        case types.USER_LIST_FAILED: return { ...state, isLoading: false, };

        case types.USER_DETAILS_LOAD: return { ...state, isLoading: true };
        case types.USER_DETAILS_SUCCESS: return { ...state, isLoading: false, userDetails: action?.result?.data };
        case types.USER_DETAILS_FAILED: return { ...state, isLoading: false, };

        case types.USER_DETAILS_UPDATE_LOAD: return { ...state, isLoading: true };
        case types.USER_DETAILS_UPDATE_SUCCESS: return { ...state, isLoading: false };
        case types.USER_DETAILS_UPDATE_FAILED: return { ...state, isLoading: false, };

        case types.API_COUNTRY_LIST_LOAD: return { ...state, isLoading: true };
        case types.API_COUNTRY_LIST_SUCCESS: return { ...state, isLoading: false, countryList: action.result?.data };
        case types.API_COUNTRY_LIST_FAILED: return { ...state, isLoading: false, };


        case types.API_STATE_LIST_LOAD: return { ...state, isLoading: true };
        case types.API_STATE_LIST_SUCCESS: return { ...state, isLoading: false, stateList: action.result?.data };
        case types.API_STATE_LIST_FAILED: return { ...state, isLoading: false, };

        // case types.USER_BLOCK_SUCCESS: return {
        //     ...state,
        //     userList: {
        //         ...state.userList,
        //         userList: state.userList.userList.map(i => {
        //             if (i._id === action.id) {
        //                 i.adminBlock = action.block ? false : true
        //             }
        //             return i
        //         })
        //     }
        // }
        //Default case
        default: return { ...state };
    }
};
