import { Table, Modal, Image, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { appconstant } from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  reportList,
  blockUser,
  postList,
  deletePost,
} from "../../redux/actions";
import images from "../../themes/appImage";
import moment from "moment";

const PostManagement = () => {
  const dispatch = useDispatch();
  const usersListData = useSelector((state) => state.postManagement.userList);
  const deleteSuccess = useSelector(
    (state) => state.postManagement.deleteSuccess
  );

  const [apiBody, setApiBody] = useState({
    offset: 0,
    limit: 10,
    search: null,
    sort: null,
    order: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [currentBlockedUser, setCurrentBlockedUser] = useState({});

  const [blockUnblockTitle, setblockUnblockTitle] = useState("Block");
  const disableModel = (record, index) => {
    Modal.confirm({
      title: appconstant?.delete,
      content: appconstant?.u_sure_delete_post,
      okText: appconstant?.yes,
      centered: true,
      cancelText: appconstant?.no,
      onOk() {
        dispatch(deletePost({ postId: record._id }));
      },
      onCancel() {},
      className: "new-button",
    });
  };

  const dataSource = usersListData?.reportList?.map((i, index) => {
    i.key = index;
    return i;
  });

  const handleTableChange = (page, sort) => {
    dispatch(
      postList({
        ...apiBody,
        offset: page.current === 1 ? 0 : (page.current - 1) * 10,
        limit: 10,
        sort: sort?.columnKey ? sort.columnKey : null,
        order:
          sort?.order === "descend" ? -1 : sort?.order === "ascend" ? 1 : null,
      })
    );
    setApiBody((prev) => ({
      ...prev,
      offset: page.current === 1 ? 0 : (page.current - 1) * 10,
      limit: 10,
      sort: sort?.columnKey ? sort.columnKey : null,
      order:
        sort?.order === "descend" ? -1 : sort?.order === "ascend" ? 1 : null,
    }));
    setCurrentPage(page.current);
  };

  const handleInputChange = (e) => {
    let value = e.target.value.trimLeft();

    dispatch(
      postList({
        ...apiBody,
        offset: 0,
        limit: 10,
        search: value,
        sort: null,
        order: null,
      })
    );
    setApiBody((prev) => ({
      ...prev,
      offset: 0,
      limit: 10,
      search: value,
      sort: null,
      order: null,
    }));
    setCurrentPage(1);
    setSearch(value);
  };
  const columns = [
    {
      title: appconstant?.srNo,
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      width: "100px",
      render: (value, item, index) => apiBody.offset + index + 1,
    },

    {
      title: appconstant?.Image,
      dataIndex: "media",
      key: "media",
      sorter: false,
      render: (value, item, index) => (
        <div className="user_detail_image_container">
          <Image
            className="user_detail_image"
            alt=""
            key={item._id}
            src={
              item?.media?.length > 0
                ? appconstant.baseUrlForFiles + item.media+"?"+Date.now()
                : images.noImage
            }
          ></Image>
        </div>
      ),
    },

    {
      title: appconstant?.userName,
      dataIndex: "userName",
      key: "userName",
      sorter: true,
      render: (value, item, index) => <div>{item?.user?.userName}</div>,
    },
    {
      title: appconstant?.description,
      dataIndex: "description",
      key: "description",
      sorter: true,
      render: (value, item, index) => (
        <div
       className="descriptionBreakWord"
        >
          <Tooltip
          style={{width:200}}
            placement={"rightTop"}
            color="#3EA1FE"
            title={item.description}
          >
            {item.description || "N/A"}
          </Tooltip>
        </div>
      ),
    },
    {
      title: appconstant?.createdAt,
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value, item, index) => (
        <div>{moment(item?.createdAt).format("DD-MM-YYYY HH:mm")}</div>
      ),
    },
    {
      title: appconstant?.action,
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => {
        return (
          <div>
            <button
              type="submit"
              onClick={() => {
                setCurrentBlockedUser(data);
                disableModel(data, index);
              }}
            >
              {appconstant.delete}
            </button>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    document.title = appconstant?.t1d_friends;
    dispatch(postList(apiBody));
  }, []);
  useEffect(() => {
    document.title = appconstant?.t1d_friends;
    if (deleteSuccess) dispatch(postList(apiBody));
  }, [deleteSuccess]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.PostManagement,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.PostManagement}
            </h6>
            <input
              type="text"
              className="iocn-search  search-bar"
              placeholder={appconstant?.search}
              value={search}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          </div>

          <Table
            dataSource={usersListData.postList}
            columns={columns}
            showSorterTooltip={false}
            pagination={{
              total: usersListData?.paginationData?.totalCount,
              current: currentPage,
            }}
            onChange={(page, _, sort) => {
              handleTableChange(page, sort);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default PostManagement;
