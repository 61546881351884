import * as Yup from 'yup';
import { validationConstants } from '../appconstant';
import { removeEmojis } from '../../utils/helpers';
const regex = {
  login: Yup.object().shape({
    email: Yup.string()
      .test(
        "trim",
        "Please enter email address.",
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$/,
        validationConstants.valEmail
      )
      .email(validationConstants.valEmail)
      .max(320, "Maximum 320 characters are allowed.")
      .required(validationConstants.reqEmail),
    password: Yup.string()
      .test(
        "trim",
        "Please enter password.",
        (value) => {

          if (value) value = removeEmojis(value)
          return value?.trim()?.length > 0
        })
      .matches(/^(\S+$)/, validationConstants.noSpace)
      .required(validationConstants.reqPassword),
  }),
  changePass: Yup.object().shape({
    oldPassword: Yup.string()
      .test(
        "trim",
        "Please enter old password.",
        (value) => {

          if (value) value = removeEmojis(value)
          return value?.trim()?.length > 0
        }
      )
      .required("Please enter old password."),

    newPassword: Yup.string()
      .min(8, "New password should be at least 8 characters long.")
      .required("Please enter new password."),
    confirmNewPassword: Yup.string()
      .test(
        "trim",
        "Please enter confirm new password.",
        (value) => {

          if (value) value = removeEmojis(value)
          return value?.trim()?.length > 0
        })
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Confirm new password must match with new password."
      )
      .required("Please enter confirm new password."),
  }),
  resetPass: Yup.object().shape({
    password: Yup.string()
      .min(8, "Password should be at least 8 characters long.")
      .required(validationConstants.reqPassword),
    confirmPassword: Yup.string()
      .required("Please enter confirm password.")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm Password must match with password."
      ),
  }),
  forgotPass: Yup.object().shape({
    email: Yup.string()
      .test(
        "trim",
        "Please enter email address.",
        (value) => value?.trim()?.length > 0
      )
      .matches(
        /^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$/,
        validationConstants.valEmail
      )
      .email(validationConstants.valEmail)
      .max(320, "Maximum 320 characters are allowed.")
      .required(validationConstants.reqEmail),
  })
}
export default regex;