const initialValues = {
  login: () => {
    return {
      email: "",
      password: "",
    };
  },
  changePass: () => {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },
  forgotPass: () => {
    return {
      email: "",
    };
  },
  resetPass: () => {
    return {
      password: "",
      confirmPassword: "",
    };
  },
};
export default initialValues;
