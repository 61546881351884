import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MainLayout from "../layout/mainlayout";
import Privateroute from "./privateRoute";
import AppSetting from "../components/App Setting";
import {
  ResetPassword,
  UserDetails,
  EditUserDetails,
  UserManagement,
  Settings,
  Login,
  ForgotPassword,
  Dashboard,
  ChangePassword,
} from "../components";
import ReportManagement from "../components/Report Management";
import TermsAndConditions from "../components/Terms And Privacy/TermsAndConditions";
import PrivacyPolicy from "../components/Terms And Privacy/privacyPolicy";
import NotificationManagement from "../components/Notification";
import SendNotification from "../components/Notification/sendNotification";
import PostManagement from "../components/Post Management";

const RoutesNew = () => {
  return (
    <div>

      <Routes>
        {/* auth module */}
        <Route path="/" element={localStorage.token ? <Navigate to={"dashboard"} /> : <Navigate to={"login"} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

        <Route path="/" element={<MainLayout />}>
          <Route
            path="/dashboard"
            element={<Privateroute component={<Dashboard />} />}
          />
          <Route
            path="/changepassword"
            element={<Privateroute component={<ChangePassword />} />}
          />
          <Route
            path="/usermanagement"
            element={<Privateroute component={<UserManagement />} />}
          />

          <Route
            path="/userdetails"
            element={<Privateroute component={<UserDetails />} />}
          />
          <Route
            path="/edituserdetails"
            element={<Privateroute component={<EditUserDetails />} />}
          />
          <Route
            path="/postmanagement"
            element={<Privateroute component={<PostManagement />} />}
          />


          <Route
            path="/reportmanagement"
            element={<Privateroute component={<ReportManagement />} />}
          />

          <Route
            path="/notificationmanagement"
            element={<Privateroute component={<NotificationManagement />} />}
          />

          <Route
            path="/sendnotification"
            element={<Privateroute component={<SendNotification />} />}
          />

          <Route
            path="/appSetting"
            element={<Privateroute component={<AppSetting />} />}
          />

          <Route
            path="/settings"
            element={<Privateroute component={<Settings />} />}
          />

        </Route>
      </Routes>
    </div>
  );
};
export default RoutesNew;
