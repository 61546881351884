import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages, } from "../../themes/appconstant";
import { toast } from "react-toastify";
import api from "../axios/apis";



function* changePassSaga({ payload, navigate }) {

  try {

    let response = yield call(api?.changePassword, payload, navigate);

    if (response?.status >= 200 && response?.status < 400) {

      yield put({ type: types.API_CHANGE_PASSWORD_LOAD_SUCCESS, result: response.data, });
      localStorage.clear()
      navigate('/login')
      toast.success(response.message, { toastId: "success" });
    }
    else {

      toast.error(response?.message, { toastId: "err" });
      yield put({ type: types.API_AUTHENTICATION_FAILED, });
    }
  }
  catch (error) {

    yield put({ type: types.API_AUTHENTICATION_ERROR, });
    toast.error(appMessages.messageStatus500, { toastId: "err" });
  }
}


export default function* rootChangePassSaga() {
  yield takeLatest(types.API_CHANGE_PASSWORD_LOAD, changePassSaga);
}
