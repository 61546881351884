import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages, } from "../../themes/appconstant";
import { toast } from "react-toastify";
import api from "../axios/apis";


function* dashboardSaga() {

    try {

        let response = yield call(api?.dashboard);

        if (response?.status >= 200 && response?.status < 400) {

            yield put({ type: types.API_DASHBOARD_SUCCESS, result: response.data, });
        }
        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_DASHBOARD_FAILED, });
        }
    }
    catch (error) {

        yield put({ type: types.API_DASHBOARD_FAILED, });
        toast.error(appMessages.messageStatus500, { toastId: "err" });
    }
}


export default function* rootDashboardSaga() {
    yield takeLatest(types.API_DASHBOARD_LOAD, dashboardSaga)
}
