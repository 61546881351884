import Method from "../helpers/methods";

const apiFunctions = {

  loginApi: (payload) => Method.post("/admin/login", payload),

  forgotPassword: (payload) => Method.post("/admin/forgot", payload),

  validateResetPasswordToken: (payload) => Method.post("/admin/linkValid", payload),

  resetPassword: (payload) => Method.post("/admin/reset", payload),

  changePassword: (payload) => Method.put("/admin/edit", payload),

  logOut: () => Method.post("/admin/logout"),

  privacyPolicy: () => Method.get("/user/privacyPolicy"),

  termsAndConditions: () => Method.get("/user/terms"),

  updatePrivacyPolicyAndTermsAndConditions: (payload) => Method.put("/admin/privacyterms", payload),

  usersList: (payload) => Method.post("/admin/userlist", payload),
  postList: (payload) => Method.post("/admin/post-list", payload),

  blockUser: (payload) => Method.get(`/admin/block/${payload._id}`),
  deletePost: (payload) => Method.delete(`/admin/post-delete/${payload.postId}`),

  userDetails: (payload) => Method.get(`/admin/view/${payload.userId}`),

  updateUserDetails: (payload) => Method.put(`/admin/editUser`, payload, true),

  reportList: (payload) => Method.post("/admin/reports", payload),

  dashboard: () => Method.get("/admin/dashboard"),

  countryList: () => Method.get("/user/country"),

  stateList: (payload) => Method.post("/user/states", payload),

  sendNotification: (payload) => Method.post("/admin/sendNot", payload),

  notificationList: (payload) => Method.post("/admin/notList", payload),
}

export default apiFunctions;