import { apiConstants as types } from "../../themes/appconstant";
const initialState = {
  isLoading: false,
  result: {},
  errorState: null,
  isExpired: null,
  msg: null,
  resetSucs: false,
  err: null,
  forgotPasswordSuccess: false,
  admin: false
};

export const authenticationReducer = (state = initialState, action) => {

  switch (action.type) {
    // Login
    case types.API_LOGIN_LOAD: return { ...state, isLoading: true, errorState: null };
    case types.API_LOGIN_SUCCESS: return { ...state, isLoading: false, result: action.result };

    // Logout
    case types.API_LOGOUT_LOAD: return { ...state, isLoading: true };
    case types.API_LOGOUT_LOAD_SUCCESS: return { ...state, isLoading: false, result: {}, errorState: null, isExpired: null, msg: null, resetSucs: false, forgotPasswordSuccess: false };

    // Forgot Password
    case types.API_FORGOT_PASSWORD_LOAD: return { ...state, errorState: null, isLoading: true };
    case types.API_FORGOT_PASSWORD_SUCCESS: return { ...state, isLoading: false, forgotPasswordSuccess: true };

    // // Check Reset Password
    case types.API_CHECK_RESET_PASSWORD_LOAD: return { ...state, errorState: null, isLoading: true };
    case types.API_CHECK_RESET_PASSWORD_SUCCESS: return { ...state, isExpired: action.result?.isReset, isLoading: false, err: action?.error };
    case types.API_CHECK_RESET_PASSWORD_MSG: return { ...state, msg: action?.payload };

    // // Reset Password
    case types.API_RESET_PASSWORD_LOAD: return { ...state, errorState: null, isLoading: true };
    case types.API_RESET_PASSWORD_SUCCESS: return { ...state, isLoading: false, resetSucs: true, admin: action?.result?.admin };

    //Authentication Failed Case
    case types.API_AUTHENTICATION_FAILED: return { ...state, isLoading: false, forgotPasswordSuccess: false };
    case types.API_AUTHENTICATION_ERROR: return { ...state, isLoading: false, forgotPasswordSuccess: false };

    // Update state
    case types.UPDATE_STATE: return { ...state, [action.key]: action.payload }

    //Default case
    default: return { ...state };
  }
};
