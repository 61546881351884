import * as Yup from "yup";
import { useFormik } from "formik";
import Textfiled1 from "../../common/Textfiled";
import React, { useEffect, useState } from "react";
import { appconstant } from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirstLetter, removeEmojis } from "../../utils/helpers";
import { Select } from 'antd';
import { userList, sendNotifications } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/lib/mentions";


const SendNotification = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const usersListData = useSelector((state) => state?.userManagement?.userList?.userList);

    const [initialValues, setInitialValues] = useState({ title: "", description: "", type: "", users: [] })
    const [users, setUsers] = useState([])

    const handleChange = (e, v) => {

        setUsers(usersListData?.map(i => {
            i.label = i.userName
            i.value = i._id

            return i
        }))

        setInitialValues({ ...formik.values, users: e })
    }

    const handleSearch = (e, v) => {

        let arr = usersListData.filter(x => x.userName.includes(e))
        let newArr = arr?.map(i => {
            i.label = i.userName
            i.value = i._id

            return i
        })
        setUsers(newArr)
    }

    const userType = [
        {
            label: "All Users",
            value: "all"
        },
        {
            label: "Custom Users",
            value: "custom"
        }
    ]

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .test(
                "trim",
                "Please enter title.",
                (value) => {

                    if (value) value = removeEmojis(value)
                    return value?.trim()?.length > 0
                })
            .matches(
                /^[a-zA-Z0-9 ]*$/,
                "Please enter valid title."
            )
            .trim()
            .required("Please enter title.")
            .max(30, "Maximum 30 characters are allowed.")
            .min(2, "Title should be at least 2 characters long."),

        description: Yup.string()
            .test(
                "trim",
                "Please enter description.",
                (value) => {

                    if (value) value = removeEmojis(value)
                    return value?.replace(/ /g, "")?.length > 0
                })
            .matches(
                /^[a-zA-Z0-9 .]*$/,
                "Please enter valid description."
            )
            .trim()
            .required("Please enter description.")
            .max(200, "Maximum 30 characters are allowed.")
            .min(2, "Description should be at least 2 characters long."),
        type: Yup.string().required("Please enter notification type.")
    });

    const onSubmit = () => {

        console.log("formik::::", formik.values)
        console.log("initialValues::::", initialValues)

        dispatch(sendNotifications({ ...initialValues, description: formik.values.description, title: formik.values.title, userId: initialValues.users }, navigate))
    };

    const formik = useFormik({
        enableReinitialize: true,
        onSubmit,
        initialValues,
        validationSchema,
    });

    useEffect(() => {
        document.title = appconstant?.t1d_friends;
        window.scrollTo(0, 0);
        dispatch(userList({ dropdown: true }))

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (usersListData) setUsers(usersListData?.map(i => {
            i.label = i.userName
            i.value = i._id

            return i
        }))
    }, [usersListData])

    return (
        <div>
            <BreadcrumbFn
                path={["dashboard", "notificationmanagement"]}
                pathName={[
                    <FontAwesomeIcon icon={faHome} />,
                    appconstant.notificationManagement,
                    appconstant.sendNotification,
                ]}
            />
            <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
            >
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 className="text-white text-capitalize ps-3">
                            {appconstant.sendNotification}
                        </h6>
                    </div>

                    <div className="wrapper_line view_modules view ">

                        <Textfiled1
                            max={30}
                            label={appconstant.title}
                            id={"title"}
                            type={"text"}
                            onChange={formik.handleChange}
                            value={capitalizeFirstLetter(formik.values.title.trimLeft())}
                            placeholder={appconstant?.title}
                            error={
                                formik.errors.title ? (
                                    <div
                                        className="error"
                                        style={{
                                            paddingLeft: "0px",
                                        }}
                                    >
                                        {formik.errors.title}
                                    </div>
                                ) : null
                            }
                        />

                        <Textfiled1
                            max={200}
                            label={appconstant.description}
                            id={"description"}
                            type={"text"}
                            onChange={formik.handleChange}
                            value={capitalizeFirstLetter(formik.values.description.trimLeft())}
                            placeholder={appconstant?.description}
                            error={
                                formik.errors.description ? (
                                    <div
                                        className="error"
                                        style={{
                                            paddingLeft: "0px",
                                        }}
                                    >
                                        {formik.errors.description}
                                    </div>
                                ) : null
                            }
                        />


                        <div className="edit-user-details-dropdown-container">

                            <label> Type</label>
                            <Select
                                className="edit-user-details-dropdown"
                                onChange={(e) => {
                                    setInitialValues({ title: formik.values.title, description: formik.values.description, type: e })
                                }}
                                options={userType}
                                value={initialValues?.type ? initialValues?.type : "Select"}
                            />

                            {
                                formik.errors.type ? (<div className="error" style={{ paddingLeft: "0px", }} > {formik.errors.type} </div>) : null
                            }
                        </div>

                        <div className="edit-user-details-dropdown-container">

                            <label> Users</label>

                            <Select
                                className="edit-user-details-dropdown"
                                onChange={(e) => handleChange(e)}
                                placeholder="Select"
                                mode="multiple"
                                filterOption={false}
                                showSearch={true}
                                onSearch={(e) => handleSearch(e)}
                                disabled={initialValues.type === "custom" ? false : true}
                                value={initialValues?.users}
                                options={users}
                            />

                            {
                                formik.errors.state ? (<div className="error" style={{ paddingLeft: "0px", }} > {formik.errors.state} </div>) : null
                            }
                        </div>

                        <div className="up-btn">
                            {" "}
                            <button type="submit" className="button-list" onClick={() => { formik.handleSubmit() }}>
                                {appconstant.send}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default SendNotification;
