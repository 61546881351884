import { useFormik } from "formik";
import Textfiled from "../../common";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { helpers, removeEmojis } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../redux/actions";
import regex from "../../themes/formikConstants/regex";
import { appconstant } from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import initialValues from "../../themes/formikConstants/initialValues";

const Changepassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = () => {
    const values = formik.values;
    const payload = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    helpers.isInternetConnected() &&
      dispatch(changePassword(payload, navigate));
  };
  const formik = useFormik({
    onSubmit,
    validationSchema: regex?.changePass,
    initialValues: initialValues?.changePass(),
  });
  useEffect(() => {
    document.title = appconstant?.t1d_friends;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "settings"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.settings,
          appconstant.changepassword,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.changepassword}
            </h6>
            <input
              type="text"
              className="search-bar"
              placeholder={appconstant?.search}
              style={{ visibility: "hidden" }}
            />
          </div>
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="wrapper_line">
              {/* Oldpassword */}
              <div className="form-group">
                <label>{appconstant.Oldpassword}</label>
                <Textfiled
                  className="form-control"
                  type="password"
                  id="oldPassword"
                  placeholder={appconstant?.oldPass}
                  value={removeEmojis(formik.values.oldPassword)}
                  onChange={formik.handleChange}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.errors.oldPassword ? (
                  <div
                    className="error"
                    style={{
                      paddingLeft: "0px",
                    }}
                  >
                    {formik.errors.oldPassword}
                  </div>
                ) : null}
              </div>
              {/* newPassword */}
              <div className="form-group">
                <label>{appconstant.NewPassword}</label>
                <Textfiled
                  value={removeEmojis(formik.values.newPassword)}
                  className="form-control"
                  type="password"
                  id="newPassword"
                  placeholder={appconstant?.newPass}
                  onChange={formik.handleChange}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.errors.newPassword ? (
                  <div
                    className="error"
                    style={{
                      paddingLeft: "0px",
                    }}
                  >
                    {formik.errors.newPassword}
                  </div>
                ) : null}
              </div>

              {/* ConfirmNewPassword */}
              <div className="form-group">
                <label>{appconstant.ConfirmNewPassword}</label>
                <Textfiled
                  value={removeEmojis(formik.values.confirmNewPassword)}
                  className="form-control"
                  type="password"
                  id="confirmNewPassword"
                  placeholder={appconstant?.confrmNewPass}
                  onChange={formik.handleChange}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.errors.confirmNewPassword ? (
                  <div
                    className="error"
                    style={{
                      paddingLeft: "0px",
                    }}
                  >
                    {formik.errors.confirmNewPassword}
                  </div>
                ) : null}
              </div>

              {/* update button */}
              <div className="up-cent">
                <button type="submit" className="button-list">
                  {appconstant.buttonupate}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Changepassword;
