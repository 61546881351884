
import React, { useEffect } from "react";
import { appconstant } from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { privacyPolicy } from "../../redux/actions";
import Linkify from 'react-linkify';

const PrivacyPolicy = () => {

    const dispatch = useDispatch()
    const terms = useSelector((state) => state.appSetting.privacyPolicy);

    useEffect(() => {
        document.title = appconstant?.t1d_friends;
        dispatch(privacyPolicy())

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="terms-and-privacy-container">

            <div className="terms-and-privacy-heading-container">
                {appconstant.PrivacyPolicy}
            </div>


            <div className="terms-and-privacy-body-container">
                <Linkify> {terms}</Linkify>
            </div>

        </div>
    );
};
export default PrivacyPolicy;
