import React from "react";
import { Layout, Menu } from 'antd';
import { useLocation } from "react-router"
import { Link, useNavigate } from "react-router-dom";
import images from "../themes/appImage";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faHome, faCogs, faBriefcase, faUser, faAngleLeft, faAngleRight, faWrench, faBell,faBlog } from "@fortawesome/free-solid-svg-icons";

const { Sider } = Layout;



const Sidebar = (props) => {

  let navigate = useNavigate();
  let location = useLocation();

  let activeKey = ""
  switch (location.pathname) {

    case "/dashboard":
      activeKey = "/dashboard"
      break;

    case "/appSetting":
      activeKey = "/appSetting"
      break;

    case "/usermanagement":
      activeKey = "/usermanagement"
      break;
      case "/postmanagement":
        activeKey = "/postmanagement"
        break;

    case "/reportmanagement":
      activeKey = "/reportmanagement"
      break;
    case "/settings":
      activeKey = "/settings"
      break;

    case "/userDetails":
      activeKey = "/usermanagement"
      break;
    case "/edituserdetails":
      activeKey = "/usermanagement"
      break;

    case "/reportdetails":
      activeKey = "/reportmanagement"
      break;

      
    case "/notificationmanagement":
      activeKey = "/notificationmanagement"
      break;

    case "/referredcandidatesdetails":
      activeKey = "/reportmanagement"
      break;
    case "/processing":
      activeKey = "/reportmanagement"
      break;

    case "/changepassword":
      activeKey = "/settings"
      break;

    default: activeKey = "/dashboard"
  }

  return (
    <Sider
      className={props.isVisible ? 'sider_left new closedd' : 'sider_left new'}
      collapsedWidth="0"
      width={props.isVisible ? '108' : '250'}
      onBreakpoint={broken => { }}
      onCollapse={(collapsed, type) => { }}
    >
      <div className="logo">
        <Link to="/dashboard">
          <img src={images.login} alt="" />
        </Link>

      </div>
      <div className="admin-text">
        <h2>Admin Panel</h2>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={[activeKey]}
        selectedKeys={[activeKey]}
        onClick={(e) => navigate(e.key)}>


        <Menu.Item key="/dashboard">
          <Link to="">
            <FontAwesomeIcon icon={faHome} />
            <span className="line_dash">Dashboard</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="/usermanagement">
          <Link to="">
            <FontAwesomeIcon icon={faUser} />
            <span className="line_dash">User Management</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/postmanagement">
          <Link to="">
            <FontAwesomeIcon icon={faBlog} />
            <span className="line_dash">Post Management</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="/reportmanagement">
          <Link to="">
            <FontAwesomeIcon icon={faBriefcase} />
            <span className="line_dash">Report Management</span>
          </Link>
        </Menu.Item>


        <Menu.Item key="/notificationmanagement">
          <Link to="">
            <FontAwesomeIcon icon={faBell} />
            <span className="line_dash">Notification Management</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="/appSetting">
          <Link to="">
            <FontAwesomeIcon icon={faWrench} />
            <span className="line_dash">App Settings</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="/settings">
          <Link to="">
            <FontAwesomeIcon icon={faCogs} />
            <span className="line_dash">Settings</span>
          </Link>
        </Menu.Item>

      </Menu>
      <div className="angle-btn">


        <button type="submit" onClick={props.handlewClick} className=" btn "> <FontAwesomeIcon className="icon-angle-left" icon={props.isVisible ? faAngleRight : faAngleLeft} /></button></div>
    </Sider>

  )
}
export default Sidebar;