import React, { useEffect } from "react";
import { appconstant } from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import DisableInputField from "../../common/DisableInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userDetails } from "../../redux/actions";
import { capitalizeFirstLetter } from "../../utils/helpers";
import moment from 'moment'

const UserDetails = (props) => {

  const location = useLocation();
  const userId = location.state
  const UserDetailsData = useSelector((state) => state.userManagement.userDetails);
  const dispatch = useDispatch()
  const schoolLevel = [
    {
      label: "High School",
      value: "highSchool"
    },
    {
      label: "Middle School",
      value: "middleSchool"
    },
    {
      label: "College",
      value: "college"
    },
    {
      label: "Graduate",
      value: "graduates"
    }

  ]
  useEffect(() => {

    document.title = appconstant?.t1d_friends;
    dispatch(userDetails(userId))
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "usermanagement"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.UserManagemen,
          appconstant.UserDetails,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.UserDetails}
            </h6>
          </div>

          <div className="wrapper_line view_modules view">

            <div className="user_detail_image_container">
              {UserDetailsData?.profileImage ? <img className="user_detail_image" src={appconstant.baseUrlForFiles + UserDetailsData?.profileImage} alt="" /> : null}
            </div>

            <DisableInputField
              label={appconstant.firstName}
              value={capitalizeFirstLetter(UserDetailsData?.firstName?.toLowerCase())}
            />

            <DisableInputField
              label={appconstant.lastName}
              value={capitalizeFirstLetter(UserDetailsData?.lastName?.toLowerCase())}
            />

            <DisableInputField
              label={appconstant.educationLevel}
              value={capitalizeFirstLetter(schoolLevel.find(x => x.value === UserDetailsData?.education)?.label)}
            />

            <DisableInputField
              label={appconstant.dob}
              value={UserDetailsData?.dob ? moment(UserDetailsData?.dob).format("MM/DD/YYYY") : "N/A"}
            />

            <DisableInputField
              label={appconstant.yod}
              value={UserDetailsData?.yod ? moment(UserDetailsData?.yod).format("YYYY") : "N/A"}
            />

            <DisableInputField
              label={appconstant.userName}
              value={UserDetailsData?.userName}
            />

            <DisableInputField
              label={appconstant.Email}
              value={UserDetailsData?.email?.toLowerCase()}
            />

            <DisableInputField
              label={appconstant.state}
              value={UserDetailsData?.state ? capitalizeFirstLetter(UserDetailsData?.state?.name?.toLowerCase()) : "N/A"}
            />

            <DisableInputField
              label={appconstant.country}
              value={UserDetailsData?.country ? capitalizeFirstLetter(UserDetailsData?.country?.name?.toLowerCase()) : "N/A"}
            />
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetails;
