import downlaod from "../images/user1.png";

const images = {
  login: require("../images/login.png").default,
  loginOptimized: require("../images/logo-login-opt.png").default,
  download: downlaod,
  down: require("../images/user5.png").default,
  login2: require("../images/logo-login.png").default,
  user5: require("../images/user2.png").default,
  user6: require("../images/user3.png").default,
  user7: require("../images/user4.png").default,
  change: require("../images/change.png").default,
  room: require("../images/room.png").default,
  noImage:require("../images/noImage.png").default
};

export default images;
