import React from "react";

//***This input field is use to show data in the view */

const DisableInputField = ({ label, value }) => {
  return (
    <div className="fields">
      <label>{label}</label>
      <p>{value} </p>
    </div>
  );
};

export default DisableInputField;