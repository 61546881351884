import React from "react" 

const Textfiled = (props) => {
    const {
        type,
        className,
        placeholder,
        disabled,
        defaultValue,
        onChange,
        value,
        id,
        onKeyDown,
    } = props

    return (
        <div className="input_fields">
            <input
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                defaultValue={defaultValue}
                className={className}
                onKeyDown={onKeyDown}
                disabled={disabled} />
        </div>
    )
}

export default Textfiled;