import { Table, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { appconstant } from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { userList, blockUser } from "../../redux/actions";
import { capitalizeFirstLetter } from "../../utils/helpers";

import moment from 'moment'
var userData = null;
const UserManagement = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const usersListData = useSelector((state) => state.userManagement.userList);
  const [apiBody, setApiBody] = useState({ offset: 0, limit: 10, search: null, sort: null, order: null });
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("")
  const [currentBlockedUser, setCurrentBlockedUser] = useState({})
  const [dataSource, setDataSource] = useState([])

  const disableModel = (record, index) => {
    Modal.confirm({
      title:
        userData?.adminBlock
          ? appconstant?.unblock
          : appconstant?.block,
      content:
        userData?.adminBlock
          ? appconstant?.u_sure_unblock_user
          : appconstant?.u_sure_block_user
      ,
      okText: appconstant?.yes,
      centered: true,
      cancelText: appconstant?.no,
      onOk() {

        dispatch(blockUser({ _id: record._id, block: currentBlockedUser?.adminBlock, edit: true }))

        setDataSource(
          usersListData?.userList?.map((i, ind) => {

            if (ind === index) i.adminBlock = !i.adminBlock
            return i
          })
        )


      },
      onCancel() { },
      className: "new-button",
    });
  };

  const handleTableChange = (page, sort) => {

    if (currentPage.toString() !== page.current.toString()) window.scrollTo(0, 0);

    dispatch(userList({ ...apiBody, offset: page.current === 1 ? 0 : (page.current - 1) * 10, limit: 10, sort: sort?.columnKey ? sort.columnKey : null, order: sort?.order === "descend" ? -1 : sort?.order === "ascend" ? 1 : null }))
    setApiBody(prev => ({ ...prev, offset: page.current === 1 ? 0 : (page.current - 1) * 10, limit: 10, sort: sort?.columnKey ? sort.columnKey : null, order: sort?.order === "descend" ? -1 : sort?.order === "ascend" ? 1 : null }))
    setCurrentPage(page.current)
  }

  const handleInputChange = (e) => {
    let value = e.target.value.trimLeft()

    dispatch(userList({ ...apiBody, offset: 0, limit: 10, search: value, sort: null, order: null }))
    setApiBody(prev => ({ ...prev, offset: 0, limit: 10, search: value, sort: null, order: null }))
    setCurrentPage(1)
    setSearch(value)
  }
  const columns = [
    {
      title: appconstant?.srNo,
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => apiBody.offset + index + 1,
      width: "100px"
    },

    {
      title: appconstant?.name,
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value, item, index) => <div style={{ width: '320px' }}>{capitalizeFirstLetter(item?.firstName.toLowerCase()) + " " + capitalizeFirstLetter(item?.lastName.toLowerCase())}</div>
    },

    {
      title: appconstant?.emailAddrs,
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (value, item, index) => <div style={{ width: '320px' }}>{item?.email}</div>

    },
    {
      title: appconstant?.dateTime,
      dataIndex: "time",
      key: "createdAt",
      sorter: true,
      width: "250px"
    },

    {
      title: appconstant?.action,
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => {

        return (
          <div>
            <button type="submit" onClick={() => { navigate("/userDetails", { state: { userId: data._id } }) }}>{appconstant.view}</button>

            <button type="submit" onClick={() => { navigate("/edituserdetails", { state: { userId: data._id } }) }}>{appconstant.edit}</button>

            <button type="submit" onClick={() => {
              userData = data
              setCurrentBlockedUser(data)
              disableModel(data, index)


            }}>
              {data?.adminBlock ? appconstant.unblock : appconstant.block}
            </button>
          </div>
        );
      },
    },
  ];

  // const dataSource = usersListData?.userList?.map((item, index) => {
  //   item.time = moment(item.createdAt).format("MM/DD/YYYY   hh:mm A")
  //   item.key = index
  //   return item
  // })
  useEffect(() => {
    document.title = appconstant?.t1d_friends;
    dispatch(userList(apiBody))
  }, []);// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    setDataSource(usersListData?.userList?.map((item, index) => {
      item.time = moment(item.createdAt).format("MM/DD/YYYY   hh:mm A")
      item.key = index
      return item
    }))
  }, [usersListData])

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.UserManagemen,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.UserManagemen}
            </h6>
            <input
              type="text"
              className="iocn-search  search-bar"
              placeholder={appconstant?.search}
              value={search}

              onChange={(e) => { handleInputChange(e) }}
            />
          </div>

          <Table

            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            pagination={{ total: usersListData?.paginationData?.totalCount, current: currentPage }}
            onChange={(page, _, sort) => { handleTableChange(page, sort) }}
          />
        </div>
      </div>
    </div>
  );
};
export default UserManagement;
