import { all, fork } from "redux-saga/effects";
import rootAuthenticationSaga from "./authenticationSaga";
import rootChangePassSaga from "./changePasswordSaga";
import rootAppSettingSaga from "./appSettingSaga";
import rootUserManagementSaga from './userManagementSaga'
import rootReportManagementSaga from './reportManagementSaga'
import rootDashboardSaga from './dashboardSaga'
import rootNotificationSaga from './notificationManagementSaga'
import rootPostManagementSaga from "./postManagementSaga";

export default function* rootSaga() {
  yield all([
    fork(rootAuthenticationSaga),
    fork(rootChangePassSaga),
    fork(rootAppSettingSaga),
    fork(rootUserManagementSaga),
    fork(rootReportManagementSaga),
    fork(rootDashboardSaga),
    fork(rootNotificationSaga),
    fork(rootPostManagementSaga)
  ]);
}
