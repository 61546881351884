import { apiConstants as types } from "../../themes/appconstant";

const initialState = {
    isLoading: false,
    userList: [],
    body: { offset: 0, limit: 10, search: null, sort: null, order: null }
};

export const ReportManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.REPORT_LIST_LOAD: return { ...state, isLoading: true };
        case types.REPORT_LIST_SUCCESS: return { ...state, isLoading: false, userList: action?.result?.data };
        case types.REPORT_LIST_FAILED: return { ...state, isLoading: false, };

        //Default case
        default: return { ...state };
    }
};
