import { takeLatest, call, put } from "redux-saga/effects";
import {
  apiConstants as types,
  appMessages,
  routes,
} from "../../themes/appconstant";
import { toast } from "react-toastify";
import api from "../axios/apis";
import { validateResetPasswordTokenMsg } from "../actions";

// Generator to run when Authentication Failed
function* authenticationFailedSaga(result) {
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  toast.error(result, { toastId: "err" });
}

function* loginSaga({ payload, navigate }) {

  try {

    let response = yield call(api.loginApi, payload);

    if (response?.status >= 200 && response?.status < 400) {

      yield put({ type: types?.API_LOGIN_SUCCESS, result: response?.data });
      localStorage.setItem('token', response?.data?.data?.accessToken)
      navigate(routes?.dashboard);
      toast.success(response?.message, { toastId: "success" });
    }
    else {

      toast.error(response?.data?.data?.message, { toastId: "err" });
      yield call(authenticationFailedSaga, response?.message);
    }
  }
  catch (error) {

    yield put({ type: types.API_AUTHENTICATION_ERROR, });
    toast.error(appMessages.messageStatus500, { toastId: "err" });
  }
}

function* forgotPasswordSaga({ payload }) {

  try {

    let response = yield call(api?.forgotPassword, payload);

    if (response?.status >= 200 && response?.status < 400) {

      yield put({ type: types?.API_FORGOT_PASSWORD_SUCCESS, result: response?.data });
    }
    else {

      toast.error(response?.data?.data?.message, { toastId: "err" });
      yield call(authenticationFailedSaga, response?.message);
    }
  }
  catch (error) {

    yield put({ type: types.API_AUTHENTICATION_ERROR, });
    toast.error(appMessages.messageStatus500, { toastId: "err" });
  }
}

function* validateResetPasswordTokenSaga({ payload, navigate }) {

  try {

    let response = yield call(api?.validateResetPasswordToken, payload);

    if (response?.status >= 200 && response?.status < 400) yield put({ type: types?.API_CHECK_RESET_PASSWORD_SUCCESS, result: response?.data });

    else {

      yield put({ type: types.API_CHECK_RESET_PASSWORD_SUCCESS, error: response?.message });
      yield put(validateResetPasswordTokenMsg(response?.message));
    }
  }
  catch (error) {

    yield put({ type: types.API_AUTHENTICATION_ERROR, });
    toast.error(appMessages.messageStatus500, { toastId: "err" });
  }
}

function* resetPasswordSaga({ payload }) {

  try {

    let response = yield call(api?.resetPassword, payload);

    if (response?.status >= 200 && response?.status < 400) yield put({ type: types.API_RESET_PASSWORD_SUCCESS, result: response?.data?.data });

    else yield put(validateResetPasswordTokenMsg(response?.message));
  }
  catch (error) {

    yield put({ type: types.API_AUTHENTICATION_ERROR, });
    toast.error(appMessages.messageStatus500, { toastId: "err" });
  }

}

function* logoutSaga({ navigate }) {

  try {

    let response = yield call(api?.logOut);

    if (response?.status >= 200 && response?.status < 400) {

      yield put({ type: types?.API_LOGOUT_LOAD_SUCCESS });
      localStorage.clear()
      navigate(routes.login);

      toast.success(response?.message, { toastId: "success" });
    }
    else {

      toast.error(response?.message, { toastId: "err" });
      yield call(authenticationFailedSaga, response?.message);
    }
  }
  catch (error) {

    toast.error(appMessages.messageStatus500, { toastId: "err" });
    navigate(routes.login);
  }

}

export default function* rootAuthenticationSaga() {
  yield takeLatest(types.API_LOGIN_LOAD, loginSaga);
  yield takeLatest(types.API_FORGOT_PASSWORD_LOAD, forgotPasswordSaga);
  yield takeLatest(
    types.API_CHECK_RESET_PASSWORD_LOAD,
    validateResetPasswordTokenSaga
  );
  yield takeLatest(types.API_RESET_PASSWORD_LOAD, resetPasswordSaga);
  yield takeLatest(types.API_LOGOUT_LOAD, logoutSaga);
}
