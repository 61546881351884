import { apiConstants as types } from "../../themes/appconstant";

const initialState = {
    isLoading: false,
    userList: [],
    body: { offset: 0, limit: 10, search: null, sort: null, order: null },
    deleteSuccess:false,
};

export const PostManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.POST_LIST_LOAD: return { ...state, isLoading: true };
        case types.POST_LIST_SUCCESS:
        return { ...state, isLoading: false, userList: action?.result?.data };
        case types.POST_LIST_FAILED: return { ...state, isLoading: false, };

        case types.POST_DELETE_LOAD: return { ...state, isLoading: true,deleteSuccess:false };
        case types.POST_DELETE_SUCCESS:
            console.log('Ca;;ed');
        return { ...state, isLoading: false, deleteSuccess:true };
        case types.POST_DELETE_FAILED: return { ...state, isLoading: false, };

        //Default case
        default: return { ...state };
    }
};
