
import React, { useEffect } from "react";
import { appconstant } from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { termsAndConditions } from "../../redux/actions";
import Linkify from 'react-linkify';

const TermsAndConditions = () => {

    const dispatch = useDispatch()
    const terms = useSelector((state) => state.appSetting.termsAndConditions);

    useEffect(() => {
        document.title = appconstant?.t1d_friends;
        dispatch(termsAndConditions())

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="terms-and-privacy-container">

            <div className="terms-and-privacy-heading-container">
                {appconstant.termsAndConditions}
            </div>


            <div className="terms-and-privacy-body-container">
                <Linkify> {terms}</Linkify>
            </div>

        </div>
    );
};
export default TermsAndConditions;
