import { apiConstants as types } from "../../themes/appconstant";

const initialState = {
    isLoading: false,
    data: {
        totalCollege: "",
        totalHighSchool: "",
        totalMiddleSchool: "",
        totalReports: "",
        totalUsers: "",
        totalGraduates: ""
    },
};

export const DashboardReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.API_DASHBOARD_LOAD: return { ...state, isLoading: true };
        case types.API_DASHBOARD_SUCCESS: return { ...state, isLoading: false, data: action?.result?.data };
        case types.API_DASHBOARD_FAILED: return { ...state, isLoading: false, };

        //Default case
        default: return { ...state };
    }
};
