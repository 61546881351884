import { apiConstants as types } from "../../themes/appconstant";

const initialState = { isLoading: false, privacyPolicy: "", termsAndConditions: "" };

export const appSettingReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.API_PRIVACY_POLICY_LOAD: return { ...state, isLoading: true };
        case types.API_PRIVACY_POLICY_SUCCESS: return { ...state, isLoading: false, privacyPolicy: action?.result?.data?.privacyPolicy };
        case types.API_PRIVACY_POLICY_FAILED: return { ...state, isLoading: false };

        case types.API_TERMS_AND_CONDITIONS_LOAD: return { ...state, isLoading: true };
        case types.API_TERMS_AND_CONDITIONS_SUCCESS: return { ...state, isLoading: false, termsAndConditions: action?.result?.data?.termConditions };
        case types.API_TERMS_AND_CONDITIONS_FAILED: return { ...state, isLoading: false };

        case types.API_UPDATE_PRIVACY_POLICY_LOAD: return { ...state, isLoading: true };
        case types.API_UPDATE_PRIVACY_POLICY_SUCCESS: return { ...state, isLoading: false, termsAndConditions: action?.payload?.terms ?? state.termsAndConditions, privacyPolicy: action?.payload?.privacyPolicy ?? state.privacyPolicy };
        case types.API_UPDATE_PRIVACY_POLICY_FAILED: return { ...state, isLoading: false };

        case types.UPDATE_STATE_FOR_APP_SETTING: return { ...state, termsAndConditions: action?.payload?.terms ?? state.termsAndConditions, privacyPolicy: action?.payload?.privacyPolicy ?? state.privacyPolicy };

        default: return { ...state };
    }
};
