import { apiConstants as types } from "../../themes/appconstant";

const initialState = {
  isLoading: false,
  msg: null,
};

export const changePassReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_CHANGE_PASSWORD_LOAD:
      return { ...state, isLoading: true };
    case types.API_CHANGE_PASSWORD_LOAD_SUCCESS:
      return { ...state, isLoading: false };

    case types.API_LOGOUT_LOAD_SUCCESS:
        localStorage.clear();
        return {
          ...state,
          isLoading: false,
          msg: null,
        };
  
    //Default case
    default:
      return { ...state };
  }
};
