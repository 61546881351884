export const appconstant = {

  // baseUrl: "http://192.168.3.175:7000/api/v1",
  // baseUrlForFiles: "http://192.168.3.175:7000/",

  // baseUrl: "http://1.6.98.141:2021/api/v1",
  // baseUrlForFiles: "http://1.6.98.141:2021/",

  baseUrl: "https://api.t1dfriends.com/api/v1",
  baseUrlForFiles: "https://api.t1dfriends.com/",

  Image: "Image",
  createdAt:"Created At",

  forgotPass: 'Forgot Password',
  emailAddrs: 'Email Address',
  send: 'Send',
  title: "Title",
  description: "Description",
  adminLogin: 'Admin Login',
  password: 'Password',
  login: 'Login',
  expired: "Expired",
  backLogin: "Back to Login",
  success: 'Success',
  resetPass: 'Reset Password',
  plz_enter_pass: 'Please enter password.',
  reset: 'Reset',
  confirmPass: 'Confirm Password',
  passChangedSuccessfully: 'Password changed successfully.',
  t1d_friends: 'T1D Friends',
  oldPass: 'Old Password',
  newPass: 'New Password',
  confrmNewPass: 'Confirm New Password',
  totalUserReg: 'Total number of user registered',
  totalJobsRefrd: 'Total number of jobs referred',
  totalHighSchoolUsers: "Total high school users",
  totalGraduateUsers: "Total graduate users",
  totalMiddleSchoolUsers: "Total middle school users",
  totalCollegeUsers: "Total college users",
  totalReportedUsers: "Total number of users reported",
  totalEmpHired: 'Total employee hired',
  totalRefferalSelected: 'Total number of referrals selected',
  totalRefferalRejected: 'Total number of referrals rejected',
  schoolLevel: "School Level",
  educationLevel: "Education Level",
  invalidImageFormat: "Only .jpg and .png files are allowed.",
  fileShouldBeLessThan20mb: "Image size should be less than 20 mb.",
  notificationManagement: "Notification Management",
  sendNotification: "Send Notification",
  dob: "Date Of Birth",
  yod: "Year Of Diagnosis",
  srNo: 'Sr. No.',
  action: 'Action',
  block: 'Block',
  unblock: 'Unblock',
  yes: 'Yes',
  ok: 'Ok',
  no: 'No',
  search: 'Search',
  jobTitle: 'Job Title',
  jobDesc: 'Job Description',
  jobId: 'Job ID',
  candName: 'Candidate Name',
  applcnId: 'Application ID',
  applcnDate: 'Application Date',
  lastDateToApply: 'Last Date To Apply',
  refrrId: 'Referrer ID',
  applcnStatus: 'Application Status',
  refrdCand: 'Referred Candidates',
  initiated: 'Initiated',
  initiate: 'Initiate',
  process: 'Process',
  processing: 'Processing',
  selected: 'Selected',
  select: 'Select',
  rejected: 'Rejected',
  reject: 'Reject',
  payToReferrer: 'Pay To Referrer',
  na: 'NA',
  logout: "Logout",
  Reports: "Reports",

  totalRerdCand: 'Total No. of Referred Candidates',
  u_sure_block_user: 'Are you sure, you want to block this user?',
  u_sure_delete_post: 'Are you sure, you want to delete this post?',
  u_sure_unblock_user: 'Are you sure, you want to unblock this user?',
  changepassword: "Change Password",

  Oldpassword: "Old Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  contactus: "Contact us",
  home: "Dashboard",
  EmailAddress: "Email Address",
  eventManagement: "Event Management",
  UserManagement: "User Management",
  ReportManagement: "Report Management",
  PostManagement: "Post Management",
  Password: "Password",
  ConfrmPassword: "Confrm Password",
  email: "Email",
  contactNo: "Contact No",
  gender: "Gender",
  age: 'Age',
  name: 'Name',
  phNum: 'Phone Number',
  dateTime: 'Date And Time',

  view: "View",
  edit: "Edit",
  delete: "Delete",
  UserManagemen: "User Management",
  PrivacyPolicy: "Privacy Policy",
  AppSetting: "App Settings",
  Name: "Name",
  John: "John Dee",
  Email: "Email Address",
  Address: "john@yopmail.com",
  Phone: "Phone Number",
  Number: "+91 9869567564",
  UserDetails: "User Details",
  EditUserDetail: "Edit User Details",
  JobTitle: "Job Title",
  JohnDee: "John Dee",
  JobID: "Job ID",
  Vacancies: "No. Of Vacancies",
  num: "356",
  Apply: "Last Date To Apply",
  DateToApply: "20/02/2023",
  JobDescription: "Job Description",
  Loerm:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  Ella: "Ella",
  Applied: "Applied Date",
  AppliedDate: "10/01/2022",
  SetStatus: "Set Status",
  PayToReferrer: "Pay To Referrer",
  JobDetails: "Job Details",
  ReferredCandidateDetails: "Referred Candidate's Details",
  settings: "Settings",
  firstName: "First Name",
  lastName: "Last Name",
  userName: "Username",
  country: "Country",
  state: "State",
  termsAndConditions: "Terms & Conditions",


  //

  //

  //
  buttonupate: "Update",
};

// Api Action type constants
export const apiConstants = {
  // Clear state
  CLEAR_STATE: "CLEAR_STATE",
  HANDLE_SIDEBAR_NAVIGATION_STATE: "HANDLE_SIDEBAR_NAVIGATION_STATE",
  HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE: "HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE",
  UPDATE_STATE: "UPDATE_STATE",

  // ************ AUTHENTICATION ************
  // Authentications types
  API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",
  API_AUTHENTICATION_ERROR: "API_AUTHENTICATION_ERROR",

  // Login
  API_LOGIN_LOAD: "API_LOGIN_LOAD",
  API_LOGIN_SUCCESS: "API_LOGIN_SUCCESS",

  // Forgot Password
  API_FORGOT_PASSWORD_LOAD: "API_FORGOT_PASSWORD_LOAD",
  API_FORGOT_PASSWORD_SUCCESS: "API_FORGOT_PASSWORD_SUCCESS",

  // Reset Password
  API_RESET_PASSWORD_LOAD: "API_RESET_PASSWORD_LOAD",
  API_RESET_PASSWORD_SUCCESS: "API_RESET_PASSWORD_SUCCESS",

  // Reset Password
  API_CHECK_RESET_PASSWORD_LOAD: "API_CHECK_RESET_PASSWORD_LOAD",
  API_CHECK_RESET_PASSWORD_SUCCESS: "API_CHECK_RESET_PASSWORD_SUCCESS",
  API_CHECK_RESET_PASSWORD_MSG: "API_CHECK_RESET_PASSWORD_MSG",
  API_CHECK_RESET_PASSWORD_FAILED: "API_CHECK_RESET_PASSWORD_FAILED",

  // Change Password
  API_CHANGE_PASSWORD_LOAD: "API_CHANGE_PASSWORD_LOAD",
  API_CHANGE_PASSWORD_LOAD_SUCCESS: "API_CHANGE_PASSWORD_LOAD_SUCCESS",

  // Logout 
  API_LOGOUT_LOAD: "API_LOGOUT_LOAD",
  API_LOGOUT_LOAD_SUCCESS: "API_LOGOUT_LOAD_SUCCESS",

  // Privacy Policy
  API_PRIVACY_POLICY_LOAD: "API_PRIVACY_POLICY_LOAD",
  API_PRIVACY_POLICY_SUCCESS: "API_PRIVACY_POLICY_SUCCESS",
  API_PRIVACY_POLICY_FAILED: "API_PRIVACY_POLICY_FAILED",

  // Terms And Conditions
  API_TERMS_AND_CONDITIONS_LOAD: "API_TERMS_AND_CONDITIONS_LOAD",
  API_TERMS_AND_CONDITIONS_SUCCESS: "API_TERMS_AND_CONDITIONS_SUCCESS",
  API_TERMS_AND_CONDITIONS_FAILED: "API_TERMS_AND_CONDITIONS_FAILED",

  // Update Privacy Policy And Terms & Conditions
  API_UPDATE_PRIVACY_POLICY_LOAD: "API_UPDATE_PRIVACY_POLICY_LOAD",
  API_UPDATE_PRIVACY_POLICY_SUCCESS: "API_UPDATE_PRIVACY_POLICY_SUCCESS",
  API_UPDATE_PRIVACY_POLICY_FAILED: "API_UPDATE_PRIVACY_POLICY_FAILED",
  UPDATE_STATE_FOR_APP_SETTING: "UPDATE_STATE_FOR_APP_SETTING",

  // User Management
  USER_LIST_LOAD: "USER_LIST_LOAD",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAILED: "USER_LIST_FAILED",

  USER_BLOCK_LOAD: "USER_BLOCK_LOAD",
  USER_BLOCK_SUCCESS: "USER_BLOCK_SUCCESS",
  USER_BLOCK_FAILED: "USER_BLOCK_FAILED",

  USER_DETAILS_LOAD: "USER_DETAILS_LOAD",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAILED: "USER_DETAILS_FAILED",

  USER_DETAILS_UPDATE_LOAD: "USER_DETAILS_UPDATE_LOAD",
  USER_DETAILS_UPDATE_SUCCESS: "USER_DETAILS_UPDATE_SUCCESS",
  USER_DETAILS_UPDATE_FAILED: "USER_DETAILS_UPDATE_FAILED",

  // Report Management
  REPORT_LIST_LOAD: "REPORT_LIST_LOAD",
  REPORT_LIST_SUCCESS: "REPORT_LIST_SUCCESS",
  REPORT_LIST_FAILED: "REPORT_LIST_FAILED",

  // Dashboard
  API_DASHBOARD_LOAD: "API_DASHBOARD_LOAD",
  API_DASHBOARD_SUCCESS: "API_DASHBOARD_SUCCESS",
  API_DASHBOARD_FAILED: "API_DASHBOARD_FAILED",


  // Country
  API_COUNTRY_LIST_LOAD: "API_COUNTRY_LIST_LOAD",
  API_COUNTRY_LIST_SUCCESS: "API_COUNTRY_LIST_SUCCESS",
  API_COUNTRY_LIST_FAILED: "API_COUNTRY_LIST_FAILED",

  // state
  API_STATE_LIST_LOAD: "API_STATE_LIST_LOAD",
  API_STATE_LIST_SUCCESS: "API_STATE_LIST_SUCCESS",
  API_STATE_LIST_FAILED: "API_STATE_LIST_FAILED",


  POST_DELETE_LOAD: "POST_DELETE_LOAD",
  POST_DELETE_SUCCESS: "POST_DELETE_SUCCESS",
  POST_DELETE_FAILED: "POST_DELETE_FAILED",

  POST_LIST_LOAD:"POST_LIST_LOAD",
  POST_LIST_SUCCESS: "POST_LIST_SUCCESS",
  POST_LIST_FAILED: "POST_LIST_FAILED",

  // Send Notification
  API_SEND_NOTIFICATION_LOAD: "API_SEND_NOTIFICATION_LOAD",
  API_SEND_NOTIFICATION_SUCCESS: "API_SEND_NOTIFICATION_SUCCESS",
  API_SEND_NOTIFICATION_FAILED: "API_SEND_NOTIFICATION_FAILED",

  // Notification List
  API_NOTIFICATION_LIST_LOAD: "API_NOTIFICATION_LIST_LOAD",
  API_NOTIFICATION_LIST_SUCCESS: "API_NOTIFICATION_LIST_SUCCESS",
  API_NOTIFICATION_LIST_FAILED: "API_NOTIFICATION_LIST_FAILED",
};

export const appMessages = {
  offline: "Your internet connection appears to be offline. Please try again.",
  // 401 message
  messageStatus401: "The user is not authorized to make the request.",
  messageStatus500: "Something went wrong.",
  resetLinkExpired: "Your password reset link has expired",
  clickBelow: "Click below to generate a new one.",
  wrongPage: "Sorry, we couldn't' find this page",
  tryAgain: "Try again searching or go to",
};

export const validationConstants = {
  valProjName:
    "No space is allowed at the beginning and name should be minimum 2 characters long.",
  valAddress: "No space allowed either at the beginning or at the end.",
  valTaskName:
    "No space is allowed at the beginning and name should be minimum 2 characters long.",
  lastNameValidate: "Please enter a valid last name.",
  firstNameValidate: "Please enter a valid first name.",
  contactNum: "Please enter a valid 8 to 15 digit contact number.",
  passLength: "Password should be minimum 6 characters long.",
  reqConfirmPassword: "Please enter confirm password.",
  reqContactNum: "Please enter contact number.",
  reqCitizenship: "Please enter citizenship.",
  // passValid:'Password must be minimum 6 characters long and maximum 20 charachters long containing at least a number, a lowercase letter, an uppercase letter, a special character without any space.',
  passValid:
    "Password should be at least 6 characters long and must include a number, a lowercase letter, an uppercase letter, a special character and no blank spaces.",

  otpLength: "Please enter 4 digit OTP.",
  valEmail: "Please enter valid email address.",
  valCitizenship: "Please enter valid name.",
  valGender: "Please enter valid text.",
  reqEmail: "Please enter email address.",
  reqAddress: "Please enter address.",
  reqGender: "Please enter gender.",
  reqProjName: "Please enter project name.",
  reqOwnerName: "Please enter name.",
  reqGroupName: "Please enter group name.",
  reqTaskName: "Please enter task name.",
  reqTaskType: "Please select task type.",
  reqName: "Please enter name.",
  reqPhone: "Please enter phone number.",
  reqPlayerPosition: "Please enter player position.",
  noSpace: "Space is not allowed.",
  space: "Space is not allowed.",
  reqPassword: "Please enter password.",
  reqOldPassword: "Please enter old password.",
  reqNewPassword: "Please enter new password.",
  reqConfirmNewPassword: "Please re-enter new password.",
  resPassword: "Please re-enter your password.",
  oldPass: "Please enter old password.",
  reqFirstName: "Please enter first name.",
  reqLastName: "Please enter last name.",
  newPass: "Please enter new password.",
  password: " Password should be at least 6 characters long.",
  confirmSpace: "Space is not allowed in confirm password.",
  match: "New password and confirm password should be same.",
  matchPassRegister: "Password and Confirm password should be same.",
  confirmPassword: "Please enter confirm password.",
  name2: "Name should be at least 2 characters long.",
  name30: "Name should be 30 characters long.",

  noBlankSpace: "Blank spaces not allowed in amount.",
  fileSize: "File size should not be more than 20 MB.",
  formats: "Only .jpg, .jpeg and .png format images are allowed.",

  valid_Number: "Please enter valid number.",
  upload_Image: "Please upload image.",
  change_Image: "Click to change image.",
  matchNewPassword: "Old password and new password should not be same.",
};

export const routes = {
  default: '/',
  login: '/login',
  forgotPass: '/forgotpassword',
  resetPass: '/resetPassword',
  dashboard: '/dashboard',
  changePass: '/changepassword',
  userMangement: '/usermanagement',
  userDetails: '/userdetails',
  editUserDetails: '/edituserdetails',
  jobsManagement: '/jobsmanagement',
  jobDetails: '/jobdetails',
  referredCandDetails: '/referredcandidatesdetails',
  settings: '/settings',
  processing: '/processing',
}